import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-awesome-modal';
import IcoExit from '../../utils/img/exit-dialog.png';
import IconExitWhite from '../../utils/img/iconExitWhite.svg';
import iconCongrats from '../../utils/img/iconCongrats.svg';
import iconLaughter from '../../utils/img/iconLaughter.svg';
import iconLike from '../../utils/img/iconLike.svg';
import iconLoved from '../../utils/img/iconLoved.svg';
import iconSupport from '../../utils/img/iconSupport.svg';
import iconSurprised from '../../utils/img/iconSurprised.svg';
import { MuiThemeProvider } from 'material-ui/styles';
import { Avatar } from 'material-ui';
import { TextFieldWithoutForm } from '../../utils/FormsComponents';
import { FooterPagination } from '../../utils/Pagination';
import PostApi from '../../../Logicas/PostApi';
import { HeaderDialog } from './style_dialogs';
import PubSub from 'pubsub-js';

require('./dialoglikes.css');

class DialogLikes extends Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      request: true,
      showLabel: true,
      id_comment: 0,
      reactionsToFilter: ['']
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.getByName = this.getByName.bind(this);
    this.handleFilterReactions = this.handleFilterReactions.bind(this);
    this.handleRemoveReaction = this.handleRemoveReaction.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentWillMount() {
    window.onscroll = () => this.handleScroll();
    PubSub.subscribe(
      'get_next_page_comments',
      function(render) {
        this.setState({ request: true });
      }.bind(this)
    );
  }

  handleScroll() {
    if (document.getElementById('#body-scroll') !== null) {
      let scrollposition = document.getElementById('#body-scroll').scrollTop;
      var max = document.getElementById('#body-scroll').scrollHeight;
      if (
        scrollposition > max / 3 &&
        this.props.like_list.length % 10 === 0 &&
        this.state.request
      ) {
        this.setState({ page: (this.state.page += 1), request: false }, () => {
          this.props.getLikes(this.props.id_post, this.state.page);
        });

        document
          .getElementById('#body-scroll')
          .scrollIntoView({ block: 'start', behavior: 'smooth' });
      }
    }
  }

  getByName(value) {
    if (value.target.value.length >= 3) {
      this.setState({ page: 1 });
      this.props.getLikesByNameUser(this.props.id_post, value.target.value);
    }
    if (value.target.value.length === 0) {
      this.setState({ page: 1 });
      this.props.getLikes(this.props.id_post, 1, true);
    }
    this.setState({ searchvalue: value.target.value });
  }

  handleFilterReactions = (value) => {
    this.setState(
      (prevState) => {
        const { reactionsToFilter } = prevState;
        const allReactions = [
          'like',
          'loved_it',
          'laughter',
          'surprized',
          'support',
          'congrats'
        ];

        if (value === '') {
          return {
            reactionsToFilter: ['']
          };
        }

        if (
          reactionsToFilter.length === allReactions.length - 1 &&
          !reactionsToFilter.includes('')
        ) {
          return {
            reactionsToFilter: ['']
          };
        }

        if (reactionsToFilter.length === 1 && reactionsToFilter[0] === '') {
          return {
            reactionsToFilter: [value]
          };
        }

        if (!reactionsToFilter.includes(value)) {
          return {
            reactionsToFilter: [...reactionsToFilter, value]
          };
        }

        return {
          reactionsToFilter: reactionsToFilter.filter(
            (reaction) => reaction !== value
          )
        };
      },
      () => {
        const { reactionsToFilter } = this.state;
        const { getLikes, id_post } = this.props;

        const id = id_post;
        const page = 1;
        const reset = true;

        getLikes(id, page, reset, reactionsToFilter);
      }
    );
  };

  handleRemoveReaction = (value) => {
    this.setState(
      (prevState) => {
        const { reactionsToFilter } = prevState;

        const updatedFilters = reactionsToFilter.filter(
          (reaction) => reaction !== value
        );

        return {
          reactionsToFilter: updatedFilters.length > 0 ? updatedFilters : ['']
        };
      },
      () => {
        const { reactionsToFilter } = this.state;
        const { getLikes, id_post } = this.props;

        const id = id_post;
        const page = 1;
        const reset = true;

        getLikes(id, page, reset, reactionsToFilter);
      }
    );
  };

  handlePageChange = (pageNumber) => {
    this.setState({ page: pageNumber }, () => {
      const { id_post, getLikes } = this.props;
      const { reactionsToFilter } = this.state;

      getLikes(id_post, pageNumber, true, reactionsToFilter);
    });
  };

  render() {
    const { visible, like_list, reactions_list } = this.props;

    const reactionsCount =
      reactions_list &&
      reactions_list.pagination &&
      reactions_list.pagination.total_count;

    const reactionsFilterList = [
      { title: 'Tudo', value: '' },
      {
        title: 'Curtida',
        value: 'like'
      },
      {
        title: 'Amei',
        value: 'loved_it'
      },
      {
        title: 'Risada',
        value: 'laughter'
      },
      {
        title: 'Surpreso',
        value: 'surprized'
      },
      {
        title: 'Apoio',
        value: 'support'
      },
      {
        title: 'Parabéns',
        value: 'congrats'
      }
    ];

    const reactionsList = {
      like: {
        icon: iconLike,
        title: 'Curtir',
        value: 'like'
      },
      loved_it: {
        icon: iconLoved,
        title: 'Amei',
        value: 'loved_it'
      },
      laughter: {
        icon: iconLaughter,
        title: 'Risada',
        value: 'laughter'
      },
      surprized: {
        icon: iconSurprised,
        title: 'Surpreso',
        value: 'surprized'
      },
      support: {
        icon: iconSupport,
        title: 'Apoio',
        value: 'support'
      },
      congrats: {
        icon: iconCongrats,
        title: 'Parabéns',
        value: 'congrats'
      }
    };

    return (
      <Modal
        visible={visible}
        style={{
          maxWidth: '711px',
          width: '100%'
        }}
        height="670px"
        effect="fadeInUp"
      >
        <MuiThemeProvider>
          <HeaderDialog />
          <div className="reaction-modal-header">
            <div className="reaction-modal-title">
              <span>
                {this.props.reactions_list.canvas_likes && 'Reações ao post'}
              </span>
            </div>
            <div onClick={this.props.closeDialog}>
              <img
                style={{
                  width: '10px',
                  height: '10px',
                  cursor: 'pointer'
                }}
                src={IcoExit}
                alt="Fechar"
              />
            </div>
          </div>

          <div className="reaction-modal-filter-container">
            {reactionsFilterList.map((reaction, index) => (
              <div
                key={index}
                className={
                  this.state.reactionsToFilter.includes(reaction.value)
                    ? 'reaction-modal-badge-filter-selected'
                    : 'reaction-modal-badge-filter'
                }
              >
                <span
                  className="reaction-modal-badge-filter-name"
                  onClick={() => this.handleFilterReactions(reaction.value)}
                >
                  {reaction.title}
                </span>
                {reaction.value !== '' &&
                  this.state.reactionsToFilter.includes(reaction.value) && (
                    <img
                      style={{
                        width: '8px',
                        height: '8px',
                        cursor: 'pointer'
                      }}
                      onClick={() => this.handleRemoveReaction(reaction.value)}
                      src={IconExitWhite}
                      alt="Fechar"
                    />
                  )}
              </div>
            ))}
          </div>

          <div className="reaction-modal-content-container">
            <div className="reaction-modal-content-counter">
              <span className="reaction-modal-counter-content">
                Total de reações : <b>{reactionsCount || 0}</b>
              </span>
            </div>
            {/* <div className="field-search">
              <TextFieldWithoutForm
                value={this.state.searchvalue}
                onChange={this.getByName}
                floatingLabelText="Busque por usuário"
              />
            </div> */}
            <div className="reaction-modal-content-list-container">
              <div className="reaction-modal-content-list">
                {reactions_list.canvas_likes &&
                reactions_list.canvas_likes.length > 0 ? (
                  reactions_list.canvas_likes.map((like, index) => (
                    <div className="reaction-modal-list-content" key={index}>
                      <div className="reaction-modal-list-user-info">
                        <Avatar src={like.user.user_image.url} />
                        <div className="reaction-modal-list-name-sector-container ">
                          <h5>{like.user.user_name}</h5>
                          <span>{like.user.user_sector}</span>
                        </div>
                      </div>
                      <Avatar
                        width="10px"
                        height="10px"
                        src={reactionsList[like.reaction].icon}
                      />
                    </div>
                  ))
                ) : (
                  <div
                    style={{ height: '100%' }}
                    className="reaction-modal-content-notfound"
                  >
                    Nenhum resultado encontrado
                  </div>
                )}
              </div>

              <div className="reaction-modal-pagination-container">
                <div style={{ width: '100%' }}>
                  <FooterPagination
                    activePage={reactions_list.pagination.current_page}
                    totalItemsCount={reactions_list.pagination.total_count}
                    itemsPerPage={5}
                    handlePageChange={this.handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </MuiThemeProvider>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    closeDialog: () =>
      dispatch({
        type: 'GET_REACTIONS',
        data: [],
        visible: false,
        reset: true
      }),
    getLikes: (id, page, reset, reactionsToFilter) => {
      dispatch(PostApi.getReactions(id, page, reset, reactionsToFilter));
    },
    getLikesByNameUser: (id, name) => {
      dispatch(PostApi.getLikesByNameUser(id, name));
    }
  };
};

let DialogLikesContainer = connect(
  (state) => ({
    like_list: state.post_reactions.data,
    reactions_list: state.post_reactions.data,
    id_post: state.post_reactions.id_post
  }),
  mapDispatchToProps
)(DialogLikes);

export default DialogLikesContainer;
