import API, {
  API_RANDOM_UPLOAD_VIDEO,
  API_V2
} from '../componentes/restservice/api.instance';
import APIDelete from '../componentes/restservice/apiDelete.instance';
import Config from '../componentes/restservice/Utils';
import moment from 'moment-timezone';
import PubSub from 'pubsub-js';
import UserApi from './UserApi';
import Axios from 'axios';
import { style } from '../style';
import { browserHistory } from 'react-router';
import { parseTagsObject, parseObjectTags } from './ParseTags';
import imageTypeFilter from '../componentes/utils/imageTypeFilter';
import errorHandler from '../componentes/utils/errorHandler';
import roleAdmin from '../componentes/utils/roleAdmin';
import { doesContainHttp } from '../componentes/validation/FormValidation';
import { storeCopy } from '../index';
import { getVideoCover } from '../componentes/utils/getVideoCover';
import { addImageProcess } from '../componentes/utils/addImageProcess';
import { canAddFiles } from '../componentes/utils/canAddFiles';

const formats = [
  '.webm',
  '.mkv',
  '.flv',
  '.vob',
  '.ogv',
  '.ogg',
  '.drc',
  '.gif',
  '.gifv',
  '.mng',
  '.avi',
  '.mov',
  '.qt',
  '.wmv',
  '.yuv',
  '.rm',
  '.rmvb',
  '.asf',
  '.amv',
  '.mp4',
  '.m4p',
  '.m4v',
  '.mpg',
  '.mp2',
  '.mpeg',
  '.mpe',
  '.mpg',
  '.mpeg',
  '.m2v',
  '.m4v',
  '.svi',
  '.3gp',
  '.3g2',
  '.mxf',
  '.roq',
  '.nsv',
  '.flv',
  '.f4v',
  '.f4p',
  '.mov',
  '.MOV',
  '.f4a',
  '.f4b',
  'video/mp4',
  'video/quicktime'
];

const isClientCipa = style.teamName.name === 'Cipa';
export default class PostApi {
  static getPostListV2(kind, raffle = '') {
    const randomId = parseInt(Math.random() * 10000000);
    return (dispatch) => {
      dispatch({
        type: 'SET_REQUEST_ACTIVE',
        randomIdRequest: randomId
      });

      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

      const kindParam = `&kind=${kind}`;
      const withRaffle = `&with_content=${raffle}`;

      Axios.get(
        `${
          style.url.urlClient
        }api/v2/canvas_publications/?page=1${roleAdmin()}&per_page=10&source=admin${kindParam}${withRaffle}`,
        Config.headers()
      )
        .then(
          function(response) {
            dispatch({
              type: 'GET_PUBLICATION_LIST',
              publication_list: this.parsePostV2(response.data)
            });
            dispatch({
              type: 'NO_SUBMITTING'
            });
            dispatch({ type: 'RESET_PAGINATION' });
            dispatch({
              type: 'DELETE_REQUEST_ACTIVE',
              randomIdRequest: randomId
            });
            dispatch({ type: 'SET_SEARCH_NOT_ACTIVE' });
            browserHistory.push(`/post/${kind}`);
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({
            type: 'DELETE_REQUEST_ACTIVE',
            randomIdRequest: randomId
          });
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getMoodSurveys(
    page = 1,
    per_page = 10,
    getAnswers = false,
    searchUser = ''
  ) {
    const randomId = parseInt(Math.random() * 10000000);
    return (dispatch) => {
      dispatch({
        type: 'SET_REQUEST_ACTIVE',
        randomIdRequest: randomId
      });

      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

      API_V2.get(
        `mood_surveys?page=${page}&per_page=${per_page}`,
        Config.headers()
      )
        .then(
          function(response) {
            browserHistory.push('/post/moodSurveys');

            if (per_page === 1) {
              dispatch({
                type: 'GET_MOOD_SURVEY',
                editMoodSurvey: response.data.mood_surveys[0],
                paginationEditMoodSurvey: response.data.pagination
              });
              if (getAnswers) {
                dispatch(
                  this.getAnswersMoodSurvey(
                    response.data.mood_surveys[0].id,
                    '',
                    searchUser,
                    1
                  )
                );
              }
            } else {
              dispatch({
                type: 'GET_PUBLICATION_LIST',
                publication_list: {
                  publications: response.data.mood_surveys
                },
                paginationMoodSurveys: response.data.pagination
              });
            }

            dispatch({
              type: 'NO_SUBMITTING'
            });
            dispatch({ type: 'RESET_PAGINATION' });
            dispatch({
              type: 'DELETE_REQUEST_ACTIVE',
              randomIdRequest: randomId
            });
            dispatch({ type: 'SET_SEARCH_NOT_ACTIVE' });
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            dispatch({
              type: 'GET_ANSWERS_UPDATE',
              getAnswers: false
            });
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({
            type: 'DELETE_REQUEST_ACTIVE',
            randomIdRequest: randomId
          });
          dispatch({
            type: 'GET_ANSWERS_UPDATE',
            getAnswers: false
          });
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getAnswersMoodSurvey(
    id,
    activeResponse = '',
    search = '',
    page = null
  ) {
    const randomId = parseInt(Math.random() * 10000000);
    return (dispatch) => {
      dispatch({
        type: 'SET_REQUEST_ACTIVE',
        randomIdRequest: randomId
      });

      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

      const queryAnswer = activeResponse ? `&answer=${activeResponse}` : '';
      const querySearch = search ? `&name=${search}` : '';
      const queryPage = Number.isInteger(page) ? `&page=${page}` : '';

      API_V2.get(
        `mood_surveys/${id}/answers?anonymous=false${queryPage}${querySearch}${queryAnswer}`,
        Config.headers()
      )
        .then(
          function(response) {
            dispatch({
              type: 'GET_ANSWERS_MOOD_SURVEY',
              answersMoodSurvey: response.data
            });
            dispatch({
              type: 'NO_SUBMITTING'
            });
            dispatch({ type: 'RESET_PAGINATION' });
            dispatch({
              type: 'DELETE_REQUEST_ACTIVE',
              randomIdRequest: randomId
            });
            dispatch({ type: 'SET_SEARCH_NOT_ACTIVE' });
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({
            type: 'DELETE_REQUEST_ACTIVE',
            randomIdRequest: randomId
          });
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getComments(id, page, reset) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API.get(
        'canvas_publications/' + id + '/canvas_comments?page=' + page,
        Config.headers()
      )
        .then(function(response) {
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          if (response.data.length > 0) {
            PubSub.publish(
              'get_next_page_comments',
              response.data.length === 10
            );
            dispatch({
              type: 'GET_COMMENTS',
              data: response.data,
              post_id: id,
              visible: true,
              reset: reset
            });
          }
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getLikes(id, page, reset) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API.get(
        'canvas_publications/' + id + '/canvas_users?page=' + page,
        Config.headers()
      )
        .then(function(response) {
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          if (response.data.length > 0) {
            PubSub.publish(
              'get_next_page_comments',
              response.data.length === 10
            );

            dispatch({
              type: 'GET_LIKES',
              data: response.data,
              post_id: id,
              visible: true,
              reset: reset
            });
          }
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getReactions(id, page, reset, reactionsToFilter) {
    const reactionParam =
      reactionsToFilter.length > 0
        ? reactionsToFilter
            .filter((reaction) => reaction.trim() !== '')
            .map((reaction) => `reaction[]=${reaction}`)
            .join('&')
        : '';

    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API_V2.get(
        `canvas_publications/${id}/canvas_likes/reactions?${reactionParam}&page=${page}&per_page=5&source=admin`,

        Config.headers()
      )
        .then(function(response) {
          console.log('response', response);
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });

          PubSub.publish('get_next_page_comments', response.data.length === 10);
          dispatch({
            type: 'GET_REACTIONS',
            data: response.data,
            post_id: id,
            visible: true,
            reset: reset
          });
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getViews({ id, page, reset, name }) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API_V2.get('canvas_publications/' + id + '/canvas_views?page=' + page, {
        ...Config.headers(),
        params: { source: 'admin', user_name: name }
      })
        .then(function(response) {
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          if (response.data.users.length > 0) {
            PubSub.publish(
              'get_next_page_comments',
              response.data.length === 10
            );
            dispatch({
              type: 'GET_VIEWS',
              data: response.data.users,
              pagination: response.data.pagination,
              post_id: id,
              visible: true,
              reset: reset
            });
          }
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getPostListDraft() {
    const randomId = parseInt(Math.random() * 10000000);
    return (dispatch) => {
      dispatch({
        type: 'SET_REQUEST_ACTIVE',
        randomIdRequest: randomId
      });
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API_V2.get('canvas_publications/drafts?page=1', Config.headers())
        .then(
          function(response) {
            browserHistory.push('/post/drafts');
            dispatch({
              type: 'GET_PUBLICATION_LIST',
              publication_list: this.parsePostV2(response.data)
            });
            dispatch({ type: 'RESET_PAGINATION' });
            dispatch({
              type: 'NO_SUBMITTING'
            });
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            dispatch({
              type: 'DELETE_REQUEST_ACTIVE',
              randomIdRequest: randomId
            });
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({
            type: 'SET_REQUEST_ACTIVE',
            randomIdRequest: randomId
          });
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getPostListDraw(page = 1) {
    return (dispatch) => {
      const randomId = parseInt(Math.random() * 10000000);
      dispatch({
        type: 'SET_REQUEST_ACTIVE',
        randomIdRequest: randomId
      });
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API.get(
        `canvas_publications?page=${page}${roleAdmin()}&with_content=raffle`,
        Config.headers()
      )
        .then((response) => {
          browserHistory.push('/post/draw');
          dispatch({
            type: 'GET_PUBLICATION_LIST',
            publication_list: this.parsePost(response.data)
          });
          dispatch({
            type: 'GET_PAGE',
            page: page
          });
          dispatch({
            type: 'NO_SUBMITTING'
          });
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          dispatch({
            type: 'DELETE_REQUEST_ACTIVE',
            randomIdRequest: randomId
          });
        })
        .catch((err) => {
          dispatch({
            type: 'DELETE_REQUEST_ACTIVE',
            randomIdRequest: randomId
          });
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getPostPage(
    typeFilter = '',
    page = 1,
    search = '',
    valueFilter = '',
    per_page = 10
  ) {
    return (dispatch) => {
      const randomId = parseInt(Math.random() * 10000000);
      dispatch({
        type: 'SET_REQUEST_ACTIVE',
        randomIdRequest: randomId
      });
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

      const searchEscaped = encodeURIComponent(search);

      switch (typeFilter) {
        case 'Tag':
          API_V2.get(
            `canvas_publications/?page=${page}&tag=${valueFilter}&search=${searchEscaped}&per_page=${per_page}&source=admin&kind=publication`,
            Config.headers()
          )
            .then(
              function(response) {
                dispatch({
                  type: 'GET_PUBLICATION_LIST',
                  publication_list: this.parsePostV2(response.data),
                  page: page
                });
                dispatch({
                  type: 'GET_PAGE',
                  page: page
                });
                if (!!search) {
                  dispatch({ type: 'SET_SEARCH_ACTIVE' });
                } else {
                  dispatch({ type: 'SET_SEARCH_NOT_ACTIVE' });
                }
                dispatch({
                  type: 'NO_SUBMITTING'
                });
                dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
                dispatch({
                  type: 'DELETE_REQUEST_ACTIVE',
                  randomIdRequest: randomId
                });
              }.bind(this)
            )
            .catch(function(err) {
              dispatch({
                type: 'DELETE_REQUEST_ACTIVE',
                randomIdRequest: randomId
              });
              dispatch({
                type: 'OPEN_ERROR',
                alert: {
                  visible: true,
                  message: errorHandler(err)
                }
              });
            });
          break;
        case 'Mês':
          let month = UserApi.parseMonth(valueFilter);
          API_V2.get(
            `canvas_publications/?page=${page}&month=${month}&search=${searchEscaped}&per_page=${per_page}&source=admin&kind=publication`,
            Config.headers()
          )
            .then(
              function(response) {
                dispatch({
                  type: 'GET_PUBLICATION_LIST',
                  publication_list: this.parsePostV2(response.data)
                });
                dispatch({
                  type: 'GET_PAGE',
                  page: page
                });
                if (!!search) {
                  dispatch({ type: 'SET_SEARCH_ACTIVE' });
                } else {
                  dispatch({ type: 'SET_SEARCH_NOT_ACTIVE' });
                }
                dispatch({
                  type: 'NO_SUBMITTING'
                });
                dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
                dispatch({
                  type: 'DELETE_REQUEST_ACTIVE',
                  randomIdRequest: randomId
                });
              }.bind(this)
            )
            .catch(function(err) {
              dispatch({
                type: 'DELETE_REQUEST_ACTIVE',
                randomIdRequest: randomId
              });
              dispatch({
                type: 'OPEN_ERROR',
                alert: {
                  visible: true,
                  message: errorHandler(err)
                }
              });
            });
          break;
        case 'Autor(a)':
          API_V2.get(
            `canvas_publications/?page=${page}&user_id=${valueFilter.id}&search=${searchEscaped}&per_page=${per_page}&source=admin&kind=publication`,
            Config.headers()
          )
            .then(
              function(response) {
                dispatch({
                  type: 'GET_PUBLICATION_LIST',
                  publication_list: this.parsePostV2(response.data)
                });
                dispatch({
                  type: 'GET_PAGE',
                  page: page
                });
                if (!!search) {
                  dispatch({ type: 'SET_SEARCH_ACTIVE' });
                } else {
                  dispatch({ type: 'SET_SEARCH_NOT_ACTIVE' });
                }
                dispatch({
                  type: 'NO_SUBMITTING'
                });
                dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
                dispatch({
                  type: 'DELETE_REQUEST_ACTIVE',
                  randomIdRequest: randomId
                });
              }.bind(this)
            )
            .catch(function(err) {
              dispatch({
                type: 'DELETE_REQUEST_ACTIVE',
                randomIdRequest: randomId
              });
              if (err.message === 'Request failed with status code 401') {
                browserHistory.push('./');
              }
              dispatch({
                type: 'OPEN_ERROR',
                alert: {
                  visible: true,
                  message: errorHandler(err)
                }
              });
            });
          break;
        default:
          Axios.get(
            `${style.url.urlClient}api/v2/canvas_publications/?page=${page}&search=${searchEscaped}&per_page=${per_page}&source=admin&kind=publication`,
            Config.headers()
          )
            .then(
              function(response) {
                dispatch({
                  type: 'GET_PUBLICATION_LIST',
                  publication_list: this.parsePostV2(response.data)
                });
                dispatch({
                  type: 'GET_PAGE',
                  page: page
                });
                if (!!search) {
                  dispatch({ type: 'SET_SEARCH_ACTIVE' });
                } else {
                  dispatch({ type: 'SET_SEARCH_NOT_ACTIVE' });
                }
                dispatch({
                  type: 'NO_SUBMITTING'
                });
                dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
                dispatch({
                  type: 'DELETE_REQUEST_ACTIVE',
                  randomIdRequest: randomId
                });
              }.bind(this)
            )
            .catch(function(err) {
              dispatch({
                type: 'DELETE_REQUEST_ACTIVE',
                randomIdRequest: randomId
              });
              dispatch({
                type: 'OPEN_ERROR',
                alert: {
                  visible: true,
                  message: errorHandler(err)
                }
              });
            });
          break;
      }
    };
  }

  static deletePost(page, is_publication, remove_draft) {
    const randomId = parseInt(Math.random() * 10000000);
    return (dispatch) => {
      dispatch({
        type: 'SET_REQUEST_ACTIVE',
        randomIdRequest: randomId
      });
      if (is_publication) {
        let API_CLIENT = API;

        if (style.teamName.name === 'youseed') {
          API_CLIENT = APIDelete;
        }

        API_CLIENT.delete('publications/' + page, Config.headers())
          .then(function(response) {
            dispatch({
              type: 'CLOSE_ALERT',
              alert: {
                visible: false
              }
            });
            dispatch({
              type: 'NO_SUBMITTING'
            });
            dispatch({
              type: 'DELETE_REQUEST_ACTIVE',
              randomIdRequest: randomId
            });
          })
          .catch(function(err) {
            if (err.message === 'Request failed with status code 401') {
              browserHistory.push('./');
            } else {
            }
            dispatch({
              type: 'DELETE_REQUEST_ACTIVE',
              randomIdRequest: randomId
            });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          });
      } else {
        let API_CLIENT = API;

        if (style.teamName.name === 'youseed') {
          API_CLIENT = APIDelete;
        }

        API_CLIENT.delete('canvas_publications/' + page, Config.headers())
          .then(
            function(response) {
              if (remove_draft) {
                dispatch(this.getPostListDraft());
              } else {
                dispatch(this.getPostListV2());
              }

              dispatch({
                type: 'CLOSE_ALERT',
                alert: {
                  visible: false
                }
              });
              dispatch({
                type: 'NO_SUBMITTING'
              });
              dispatch({
                type: 'DELETE_REQUEST_ACTIVE',
                randomIdRequest: randomId
              });
            }.bind(this)
          )
          .catch(function(err) {
            if (err.message === 'Request failed with status code 401') {
              browserHistory.push('./');
            } else {
            }
            dispatch({
              type: 'DELETE_REQUEST_ACTIVE',
              randomIdRequest: randomId
            });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          });
      }
    };
  }

  // TODO: remove this duplicate after API migration
  static editPost(id, is_publication, redirect = true) {
    const randomId = parseInt(Math.random() * 10000000);
    return (dispatch) => {
      if (!is_publication) {
        dispatch({
          type: 'SET_REQUEST_ACTIVE',
          randomIdRequest: randomId
        });
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
        API.get('canvas_publications/' + id, Config.headers())
          .then(
            function(response) {
              var post_parsed = this.parseCanvas(
                response.data,
                style.CurrencyName.name
              );
              dispatch({
                type: 'formPostEdit',
                kind: 'canvas',
                canvas: post_parsed
              });
              dispatch({
                type: 'NO_SUBMITTING'
              });
              dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });

              const {
                data: { canvas_contents: contents }
              } = response;
              dispatch(this.checkDrawType(contents));

              const { kind } = response.data;

              dispatch({
                type: 'DELETE_REQUEST_ACTIVE',
                randomIdRequest: randomId
              });

              if (redirect) {
                if (kind === 'form') {
                  browserHistory.push('/post/form/edit');
                } else {
                  browserHistory.push('/post/edit');
                }
              }
            }.bind(this)
          )
          .catch(function(err) {
            dispatch({
              type: 'DELETE_REQUEST_ACTIVE',
              randomIdRequest: randomId
            });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          });
      } else {
        dispatch({
          type: 'OPEN_ALERT_NO_EDIT',
          alert: true
        });
      }
    };
  }

  static editPostV2(id, is_publication) {
    return (dispatch) => {
      if (!is_publication) {
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
        Axios.get(
          `${style.url.urlClient}api/v2/canvas_publications/` + id,
          Config.headers(),
          Config.headers()
        )
          .then(function(response) {
            var post_parsed = this.parseCanvas(
              response.data,
              style.CurrencyName.name
            );
            dispatch({
              type: 'formPostEdit',
              canvas: post_parsed,
              kind: 'canvas'
            });
            dispatch({
              type: 'NO_SUBMITTING'
            });
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
            browserHistory.push('/post/edit');
          })
          .catch(function(err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          });
      } else {
        dispatch({
          type: 'OPEN_ALERT_NO_EDIT',
          alert: true
        });
      }
    };
  }

  static savePostDraw(id, values, edit) {
    return (dispatch) => {
      dispatch({
        type: 'SHOW_STATUS',
        enablesubmit: false
      });
      var CONFI = Config.headers();
      CONFI.onUploadProgress = (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        dispatch({
          type: 'SHOW_PROGRESS',
          alert: {
            visible: true,
            percentage: percentCompleted
          }
        });
      };
      var formData = new FormData();
      formData.append('text', values);
      formData.append('kind', 'draw');
      API.post(
        'canvas_publications/' + id + '/canvas_contents',
        formData,
        CONFI
      )
        .then(function(response) {
          dispatch({
            type: 'formPost',
            kind: 'draw',
            value: response.data
          });
          dispatch({
            type: 'SHOW_PROGRESS',
            alert: {
              visible: false,
              percentage: 0
            }
          });
          dispatch({
            type: 'NO_SUBMITTING'
          });
          try {
            dispatch({
              type: 'formPostEdit',
              kind: 'draw',
              value: response.data,
              insertText: true
            });
          } catch (e) {}

          dispatch({
            type: 'HIDE_STATUS',
            enablesubmit: true
          });
          PubSub.publish('re-render-canvas', true);
        })
        .catch(function(err) {
          if (err.message === 'Request failed with status code 401') {
            browserHistory.push('./');
          } else {
          }
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: 'Um erro ocorreu'
            }
          });
        });
    };
  }

  static patchPost(values) {
    return (dispatch) => {
      var CONFI = Config.headers();
      CONFI.onUploadProgress = (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        dispatch({
          type: 'OPEN_DIALOG_PROGRESS',
          alert: {
            visible: true,
            percentage: percentCompleted
          }
        });
      };

      var publication = this.buildFormData(values);
      API.patch('publications/' + values.id, publication, CONFI)
        .then(
          function(response) {
            this.getPostList();
            browserHistory.push('/post/publication');
            dispatch({
              type: 'CLOSE_ALERT',
              alert: {
                visible: false
              }
            });
            dispatch({
              type: 'NO_SUBMITTING'
            });
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static canvasPublish = (id, send_push = null) => {
    return async (dispatch) => {
      try {
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

        const formData = new FormData();

        formData.append('draft', true);
        send_push && formData.append('send_push', send_push);

        const { data } = await API.patch(
          'canvas_publications/' + id + '?draft=true',
          formData,
          Config.headers()
        );

        dispatch({
          type: 'formPost',
          kind: 'text',
          value: data
        });

        dispatch({
          type: 'NO_SUBMITTING'
        });
        this.getPostListV2('publication');
        browserHistory.push('/post/publication');
      } catch (error) {
        if (
          error.response.data.message ===
            'Data do sorteio menor que data de publicação' ||
          (error.response.data.errors !== undefined &&
            error.response.data.errors[0] !== undefined &&
            error.response.data.errors[0] ===
              'Data do sorteio menor que data de publicação') ||
          error.message === 'Data do sorteio menor que data de publicação'
        ) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: 'Data ou hora incorretos',
              submessage:
                'Para publicar o sorteio escolha as datas, de hoje, ou posterior. Para o horário, recomendamos uma margem de, no mínimo, 15 minutos a mais do que o atual.'
            }
          });
        } else {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(error)
            }
          });
        }
      } finally {
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
      }
    };
  };

  static canvasPublishSchedule(id, publish_in, expiration_in) {
    return async (dispatch) => {
      try {
        const formData = new FormData();
        formData.append('publish_in', publish_in);
        if (expiration_in !== undefined) {
          formData.append('expires_at', expiration_in);
        }

        const { data } = await API.patch(
          `canvas_publications/${id}`,
          formData,
          Config.headers()
        );

        dispatch({
          type: 'formPost',
          kind: 'text',
          value: data
        });
        dispatch({
          type: 'NO_SUBMITTING'
        });
        dispatch({
          type: 'SCHEDULE_PUBLICATION_SUCCESS'
        });

        setTimeout(() => {
          dispatch({
            type: 'SCHEDULE_MODAL_HIDE'
          });
          dispatch({
            type: 'SCHEDULE_PUBLICATION_RESET'
          });

          browserHistory.push('/post/publication');
        }, 1500);
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  }

  static canvasPublishSurvery(id) {
    return (dispatch) => {
      let formData = new FormData();
      formData.append('draft', true);
      API.patch('canvas_publications/' + id, formData, Config.headers())
        .then(
          function(response) {
            dispatch({
              type: 'formPost',
              kind: 'text',
              value: response.data
            });
            dispatch(this.getSurveyList(1));
            browserHistory.push('/post/survey');
            dispatch({
              type: 'NO_SUBMITTING'
            });
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static setFixedPost = (postId, value) => {
    return async (dispatch) => {
      try {
        const formData = new FormData();

        formData.append('fixed', value);

        const { data: postContent } = await API.patch(
          'canvas_publications/' + postId,
          formData,
          Config.headers()
        );

        const post_parsed = this.parseCanvas(
          postContent,
          style.CurrencyName.name
        );

        dispatch({
          type: 'formPostEdit',
          canvas: post_parsed,
          kind: 'canvas'
        });
        dispatch({
          type: 'NO_SUBMITTING'
        });
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  };

  static setNotificationEnabled = (postId, value) => {
    return async (dispatch) => {
      try {
        const formData = new FormData();

        if (value) {
          formData.append('send_push', value);
          formData.append('draft', true);
        }

        const { data: postContent } = await API.patch(
          'canvas_publications/' + postId,
          formData,
          Config.headers()
        );

        const post_parsed = this.parseCanvas(
          postContent,
          style.CurrencyName.name
        );

        dispatch({
          type: 'formPostEdit',
          canvas: post_parsed,
          kind: 'canvas'
        });
        dispatch({
          type: 'NO_SUBMITTING'
        });
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  };

  static setLikesEnabled = (postId, value) => {
    return async (dispatch) => {
      try {
        const formData = new FormData();

        formData.append('likes_enabled', value);

        const { data: postContent } = await API.patch(
          'canvas_publications/' + postId,
          formData,
          Config.headers()
        );

        const post_parsed = this.parseCanvas(
          postContent,
          style.CurrencyName.name
        );

        dispatch({
          type: 'formPostEdit',
          canvas: post_parsed,
          kind: 'canvas'
        });
        dispatch({
          type: 'NO_SUBMITTING'
        });
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  };

  static setCommentsEnabled = (postId, value) => {
    return async (dispatch) => {
      try {
        const formData = new FormData();

        formData.append('comments_enabled', value);

        const { data: postContent } = await API.patch(
          'canvas_publications/' + postId,
          formData,
          Config.headers()
        );

        const post_parsed = this.parseCanvas(
          postContent,
          style.CurrencyName.name
        );

        dispatch({
          type: 'formPostEdit',
          canvas: post_parsed,
          kind: 'canvas'
        });
        dispatch({
          type: 'NO_SUBMITTING'
        });
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  };

  static setLimitDateGamification = (postId, value) => {
    return async (dispatch) => {
      try {
        const formData = new FormData();

        formData.append('not_score_after', value);

        const { data: postContent } = await API.patch(
          'canvas_publications/' + postId,
          formData,
          Config.headers()
        );

        const post_parsed = this.parseCanvas(
          postContent,
          style.CurrencyName.name
        );

        dispatch({
          type: 'formPostEdit',
          canvas: post_parsed,
          kind: 'canvas'
        });
        dispatch({
          type: 'NO_SUBMITTING'
        });
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  };

  static setMinComments = (postId, value) => {
    return async (dispatch) => {
      try {
        const formData = new FormData();

        formData.append('min_size_comments', value);

        const { data: postContent } = await API.patch(
          'canvas_publications/' + postId,
          formData,
          Config.headers()
        );

        const post_parsed = this.parseCanvas(
          postContent,
          style.CurrencyName.name
        );

        dispatch({
          type: 'formPostEdit',
          canvas: post_parsed,
          kind: 'canvas'
        });
        dispatch({
          type: 'NO_SUBMITTING'
        });
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  };

  static setSpecialPost = (postId, value, message) => {
    return async (dispatch) => {
      try {
        const formData = new FormData();
        formData.append('special', value);
        if (message) {
          formData.append('interaction_message', message);
        } else {
          formData.append('interaction_message', '');
        }

        const { data: postContent } = await API.patch(
          'canvas_publications/' + postId,
          formData,
          Config.headers()
        );

        const post_parsed = this.parseCanvas(
          postContent,
          style.CurrencyName.name
        );
        dispatch({
          type: 'formPostEdit',
          canvas: post_parsed,
          kind: 'canvas'
        });
        dispatch({
          type: 'NO_SUBMITTING'
        });
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  };

  static canvasUpdate(id, values) {
    return (dispatch) => {
      var formData = new FormData();
      if (Array.isArray(values.tags)) {
        values.tags.forEach(function(tag) {
          !!tag.value
            ? formData.append('tags[]', tag.value)
            : formData.append('tags[]', '');
        });
      }
      if (values.score !== undefined) {
        formData.append('score', values.score);
      }
      if (values.title !== undefined) {
        formData.append('title', values.title);
      }
      API.patch('canvas_publications/' + id, formData, Config.headers())
        .then(function(response) {
          dispatch({
            type: 'formPost',
            kind: 'text',
            value: response.data
          });

          if (values.tags) {
            dispatch({
              type: 'formPostEdit',
              kind: 'tags',
              value: values.tags.filter((tag) => tag.value !== undefined)
            });
          }
          dispatch({
            type: 'NO_SUBMITTING'
          });

          dispatch(PostApi.editPost(id, false, false));
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static canvasUpdateInTagExpressions(id, tags) {
    return (dispatch) => {
      const tagsObjectParse = parseTagsObject(tags);
      const objectSaveCanvas = {
        expression: tagsObjectParse
      };
      const objectToJson = JSON.stringify(objectSaveCanvas);
      API.patch(`canvas_publications/${id}`, objectToJson, {
        headers: Object.assign(Config.headers().headers, {
          'content-type': 'application/json'
        })
      })
        .then((response) => {
          dispatch({
            type: 'formPost',
            kind: 'text',
            value: response.data
          });
          dispatch({
            type: 'NO_SUBMITTING'
          });
        })
        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: 'Um erro ocorreu'
            }
          });
        });
    };
  }

  static createPost(values) {
    return (dispatch) => {
      var CONFI = Config.headers();
      CONFI.onUploadProgress = (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        dispatch({
          type: 'OPEN_DIALOG_PROGRESS',
          alert: {
            visible: true,
            percentage: percentCompleted
          }
        });
      };
      var publication = this.buildFormData(values);
      API.post('publications/', publication, CONFI)
        .then(
          function(response) {
            this.getPostList();
            dispatch({
              type: 'CLOSE_ALERT',
              alert: {
                visible: false
              }
            });
            dispatch({
              type: 'NO_SUBMITTING'
            });
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getPostPageDraft(page) {
    const randomId = parseInt(Math.random() * 10000000);
    return (dispatch) => {
      dispatch({
        type: 'SET_REQUEST_ACTIVE',
        randomIdRequest: randomId
      });
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API_V2.get(
        `canvas_publications/drafts?page=${page}&per_page=10`,
        Config.headers()
      )
        .then(
          function(response) {
            dispatch({
              type: 'GET_MORE_PUBLICATIONS',
              publication_list: this.parsePostV2(response.data),
              page: page
            });
            dispatch({
              type: 'NO_SUBMITTING'
            });
            dispatch({
              type: 'DELETE_REQUEST_ACTIVE',
              randomIdRequest: randomId
            });
            browserHistory.push('/post/drafts');
            dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({
            type: 'DELETE_REQUEST_ACTIVE',
            randomIdRequest: randomId
          });
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  // TODO: remove this duplicate after API migration
  static parsePost(publications) {
    var publications_parsed = {
      pages: 0,
      size: 0,
      publications: []
    };
    if (publications !== undefined) {
      if (publications.length > 0) {
        publications.forEach((element) => {
          var post = {};
          post.id = element.id;
          post.comments = element.size_comments;
          post.user_name = element.user.name;
          post.title = element.title;
          post.likes = element.likes;
          post.publish_in = element.publish_in;
          post.visualization = element.visualization;
          post.created_at = moment
            .tz(element.published_at, 'Europe/Berlin')
            .format('DD/MM/YYYY');
          post.contents = [];
          post.is_publication = element.is_publication;
          post.tags = element.tags;
          post.size = element.size;
          // HOTFIX - remove after solving tags issue
          if (post.tags.length === 0) {
            post.tags.push({
              description: 'Desenvolvedores',
              name: 'Dev',
              valide: true
            });
          }
          if (element.expression) {
            post.tagsExpression = parseObjectTags(element.expression);
          }
          element.canvas_contents.forEach((content) => {
            if (post.kind === undefined) {
              if (content.kind === 'image') {
                post.kind = 'image';
                if (content.canvas_content_images.length > 0) {
                  post.contents.push(content.canvas_content_images[0].image);
                }
              }
              if (
                content.kind === 'carousel' &&
                content.canvas_content_images.length > 1
              ) {
                post.kind = 'carousel';
                if (content.canvas_content_images.length > 0) {
                  post.contents = content.canvas_content_images;
                }
              }

              if (content.kind === 'video' && content.canvas_content_videos) {
                post.kind = 'video';
                if (content.canvas_content_videos.length > 0) {
                  post.contents.push(
                    content.canvas_content_videos[0].video.preview_image.url
                  );
                }
              }

              if (content.kind === 'text' && content.canvas_content_videos) {
                post.kind = 'text';
              }
              if (content.kind === 'link' && content.canvas_content_videos) {
                post.kind = 'link';
              }
            }
          });
          element.canvas_contents.forEach((content) => {
            if (content.kind === 'raffle') {
              post.kind = 'raffle';
              post.raffleId = content.id;
            }
          });
          if (publications[0].size === null) {
            throw new Error(
              'Comportamento inesperado da aplicação. Tente atualizar a página.'
            );
          }
          publications_parsed.publications.push(post);
          publications_parsed.pages = publications[0].size[1];
          publications_parsed.size = publications[0].size[0];
        });
      }
    }
    return publications_parsed;
  }

  static parsePostV2(publications) {
    var publications_parsed = {
      pages: 0,
      size: 0,
      publications: []
    };
    if (publications !== undefined) {
      if (publications.length > 0) {
        publications.forEach((element) => {
          var post = {};
          post.id = element.id;
          post.size = element.size;
          post.comments = element.comments_amount;
          post.user_name = element.author.name;
          post.title = element.title;
          post.cover = element.cover;
          post.likes = element.likes_amount;
          post.publish_in = element.publish_in;
          post.visualization = element.visualizations_amount;
          post.unique_views = element.unique_views;
          post.created_at = moment
            .tz(element.published_at, 'Europe/Berlin')
            .format('DD/MM/YYYY');
          post.contents = [];
          post.is_publication = element.is_publication;
          post.tags = element.tags;
          if (element.expression) {
            post.tagsExpression = parseObjectTags(element.expression);
          }
          publications_parsed.publications.push(post);
          publications_parsed.size = element.size[0];
        });
      }
    }
    return publications_parsed;
  }

  static buildFormData(values) {
    let formData = new FormData();
    formData.append('title', values.title_publication);
    formData.append('textpublication', values.description_publication);
    values.tags_publication.forEach(function(tag, i) {
      formData.append('tags[]', tag);
    });
    formData.append('valuepost', values.score_publication);
    if (values.img_post !== undefined && values.img_post[0] !== undefined) {
      if (formats.includes(values.img_post[0].type)) {
        formData.append('kind', 'video');
        formData.append('video', values.img_post[0]);
      } else {
        formData.append('kind', 'image');
        formData.append('image', values.img_post[0]);
      }
    }
    if (values.link_publication !== null) {
      let link = values.link_publication.replace(/ /g, '');

      formData.append('media_link', link);
      formData.append('kind', 'video');
    }

    return formData;
  }

  static parsePostEdit(publication) {
    var post = {};
    post.id = publication.id;
    if (publication.image.url !== null) {
      post.img = publication.image.url;
    } else if (publication.video.url !== null) {
      post.img = publication.video.url;
      post.type = 'video/mp4';
    } else if (publication.media_link !== null) {
      if (publication.media_link !== null) {
        post.type = 'extern_link';
        post.link_publication = publication.media_link;
      }
    }
    post.title_publication = publication.title;
    post.description_publication = publication.textpublication;
    post.tags_publication = [];
    publication.tag.forEach(function(tag) {
      post.tags_publication.push(tag.name);
    });
    if (publication.valuepost !== null) {
      post.score_publication = publication.valuepost;
    } else {
      post.score_publication = 0;
    }

    post.createad_at = moment
      .tz(publication.created_at, 'Europe/Berlin')
      .format('DD/MM/YYYY');
    post.createad_at = post.createad_at.replace('/', '');
    post.createad_at = post.createad_at.replace('/', '');
    post.user_name = publication.userpublication.name;
    post.user_image = publication.userpublication.image.url;
    return post;
  }

  static parseCanvas(values) {
    var canvas = {};
    canvas.render = true;
    canvas.end_date = values.end_date;
    canvas.score = values.score;
    canvas.title = values.title;
    canvas.id = values.id;
    canvas.tags = [];
    canvas.texts = [];
    canvas.images = [];
    canvas.carousel = [];
    canvas.video = [];
    canvas.elements = [];
    canvas.links = [];
    canvas.textsid = [];
    canvas.special_post = values.special;
    canvas.interaction_message = values.interaction_message;
    canvas.fixed_post = values.fixed;
    canvas.likes_enabled = values.likes_enabled;
    canvas.comments_enabled = values.comments_enabled;
    canvas.min_size_comments = values.min_size_comments;
    canvas.report_success_min_comments = canvas.send_push = values.send_push;
    canvas.user = values.user;
    canvas.publish_in = values.publish_in
      ? new Date(values.publish_in)
      : new Date();
    canvas.expiration_in = values.expiration_in
      ? new Date(values.expiration_in)
      : new Date();
    canvas.not_score_after = values.not_score_after
      ? new Date(values.not_score_after)
      : undefined;
    canvas.published_at = values.published_at
      ? new Date(values.published_at)
      : null;
    if (values.expression) {
      canvas.tagsExpression = parseObjectTags(values.expression);
    }
    if (Array.isArray(values.canvas_contents)) {
      values.canvas_contents.forEach(function(element) {
        if (element.kind === 'text') {
          canvas.textsid.push(element);
          canvas.texts.push(element);
        }
        canvas.elements.push(element);
      });
    }
    canvas.text = [];
    if (Array.isArray(values.canvas_contents)) {
      values.canvas_contents.forEach(function(element) {
        if (element.kind === 'text') {
          canvas.text.push(element);
        }
      });
    }
    if (Array.isArray(values.tags)) {
      values.tags.forEach(function(tag) {
        canvas.tags.push({ value: tag.name, label: tag.name });
      });
    }
    return canvas;
  }

  static parseDetailsForm(values, teamName) {
    let formDetails = {};
    let auxInteraction = [];
    formDetails.id = values.id;
    formDetails.teamName = teamName;
    formDetails.title = values.title;
    formDetails.score = values.score;
    formDetails.likes = values.likes;
    formDetails.end_date = values.end_date;
    formDetails.not_score_after = values.not_score_after;
    formDetails.size_comments = values.size_comments;
    formDetails.visualization = values.visualization;
    formDetails.users_likes = values.userslikes;
    formDetails.users_comments = values.canvas_comments;
    formDetails.contents = values.canvas_contents;
    formDetails.form_answers_count = values.form_answers_count;
    formDetails.user_visualizations = values.user_visualizations;
    formDetails.user_visualizations_count = values.user_visualizations_count;

    formDetails.interaction = [];
    if (Array.isArray(values.userslikes)) {
      values.userslikes.forEach(function(users) {
        let users_id;
        users_id = users.id;

        auxInteraction.push(users_id);
      });
    }

    if (Array.isArray(values.canvas_comments)) {
      values.canvas_comments.forEach(function(users) {
        let users_id;
        users_id = users.user.id;

        auxInteraction.push(users_id);
      });

      formDetails.interaction = auxInteraction.filter(function(
        elem,
        index,
        self
      ) {
        return index === self.indexOf(elem);
      });
    }

    formDetails.tags = [];
    formDetails.texts = [];
    formDetails.images = [];
    formDetails.textsid = [];
    formDetails.carousel = [];
    formDetails.elements = [];
    if (Array.isArray(values.formDetails_contents)) {
      values.formDetails_contents.forEach(function(element) {
        if (element.kind === 'text') {
          formDetails.textsid.push(element);
          formDetails.texts.push(element);
        }
        formDetails.elements.push(element);
      });
    }

    formDetails.text = [];
    if (Array.isArray(values.formDetails_contents)) {
      values.formDetails_contents.forEach(function(element) {
        if (element.kind === 'text') {
          formDetails.text.push(element);
        }
      });
    }
    if (Array.isArray(values.tags)) {
      values.tags.forEach(function(tag) {
        formDetails.tags.push({ id: tag.id, name: tag.name, label: tag.name });
      });
    }
    formDetails.contents_img = [];
    values.canvas_contents.forEach((content) => {
      if (formDetails.kind === undefined) {
        if (content.kind === 'image') {
          formDetails.kind = 'image';
          if (content.canvas_content_images.length > 0) {
            formDetails.contents_img.push(
              content.canvas_content_images[0].image
            );
          }
        }
        if (
          content.kind === 'carousel' &&
          content.canvas_content_images.length > 1
        ) {
          formDetails.kind = 'carousel';
          if (content.canvas_content_images.length > 0) {
            formDetails.contents_img = content.canvas_content_images;
          }
        }

        if (content.kind === 'video' && content.canvas_content_videos) {
          formDetails.kind = 'video';
          if (content.canvas_content_videos.length > 0) {
            formDetails.contents_img.push(
              content.canvas_content_videos[0].video.preview_image.url
            );
          }
        }

        if (content.kind === 'text' && content.canvas_content_videos) {
          formDetails.kind = 'text';
        }
        if (content.kind === 'link' && content.canvas_content_videos) {
          formDetails.kind = 'link';
        }
      }
    });

    return formDetails;
  }

  static saveFormPublish(values, schedule) {
    return (dispatch) => {
      if (values.question_only_0) {
        let i = 0;
        Object.entries(values).forEach(([key, value]) => {
          if (key === `question_only_${i}`) {
            let formData = new FormData();
            formData = this.buildFormSurveyOnly(values, `optionOnly_${i}_`);
            formData.append('text', value);
            formData.append('end_date', values.end_date);
            API.post(
              `canvas_publications/${values.id}/canvas_contents`,
              formData,
              Config.headers()
            )
              .then(() => {})
              .catch((err) => {
                dispatch({
                  type: 'OPEN_ERROR',
                  alert: {
                    visible: true,
                    message: errorHandler(err)
                  }
                });
              });
            i++;
          }
        });
      }

      if (values.question_multiple_0) {
        let i = 0;
        Object.entries(values).forEach(([key, value]) => {
          if (key === `question_multiple_${i}`) {
            let formData = new FormData();

            formData = this.buildFormSurveyOnly(values, `optionMultiple_${i}_`);
            formData.append('text', value);
            formData.append('end_date', values.end_date);
            formData.append('allows_more_than_one_vote', true);

            API.post(
              `canvas_publications/${values.id}/canvas_contents`,
              formData,
              Config.headers()
            )
              .then(() => {})
              .catch((err) => {
                dispatch({
                  type: 'OPEN_ERROR',
                  alert: {
                    visible: true,
                    message: errorHandler(err)
                  }
                });
              });
            i++;
          }
        });
      }

      if (values.question_short_0) {
        let i = 0;
        Object.entries(values).forEach(([key, value]) => {
          if (key === `question_short_${i}`) {
            let formData = new FormData();
            formData.append('[open_question_attributes][question]', value);
            formData.append('kind', 'open_question');
            API.post(
              `canvas_publications/${values.id}/canvas_contents`,
              formData,
              Config.headers()
            )
              .then(() => {})
              .catch((err) => {
                dispatch({
                  type: 'OPEN_ERROR',
                  alert: {
                    visible: true,
                    message: errorHandler(err)
                  }
                });
              });
            i++;
          }
        });
      }
      if (schedule) {
        dispatch({ type: 'HIDE_MODAL_BIRTHDAY' });
      } else {
        dispatch(this.canvasPublish(values.id));
      }
    };
  }

  static buildFormSurveyOnly(values, optionName) {
    let form = new FormData();
    let i = 0;

    Object.entries(values).forEach(([key, value]) => {
      if (key === `${optionName}${i}`) {
        form.append('[surveys_attributes][][title]', value);
        i++;
      }
    });

    form.append('kind', 'survey');
    return form;
  }

  static saveCanvasInitial(values, isForm = false) {
    return (dispatch) => {
      let canvasCreateInitial = new FormData();
      canvasCreateInitial.append('title', values.title);
      canvasCreateInitial.append('score', values.score);
      canvasCreateInitial.append('not_score_after', values.not_score_after);
      if (values.tags) {
        values.tags.forEach((tagsValues) => {
          canvasCreateInitial.append('tags[]', tagsValues.value);
        });
      }
      if (isForm) {
        canvasCreateInitial.append('kind', 'form');
        canvasCreateInitial.append('end_date', values.end_date);
      }
      dispatch({
        type: 'SHOW_STATUS',
        enablesubmit: false
      });
      API.post('canvas_publications/', canvasCreateInitial, Config.headers())
        .then(
          function(response) {
            var post_parsed = this.parseCanvas(response.data);

            dispatch({
              type: 'formPostEdit',
              canvas: post_parsed,
              kind: 'canvas'
            });
            dispatch({
              type: 'UPDATE_STATUS_REQUEST',
              message: 'Alterações salvas em RASCUNHOS'
            });
            setTimeout(() => {
              dispatch({
                type: 'UPDATE_STATUS_REQUEST',
                message: ''
              });
            }, 5000);
            dispatch({
              type: 'HIDE_STATUS',
              enablesubmit: true
            });
            dispatch({
              type: 'SHOW_PROGRESS',
              alert: {
                visible: false,
                percentage: 0
              }
            });
            /* dispatch({
              type: 'NO_SUBMITTING',
            }); */
            if (values.videoEmbededUrl !== '') {
              dispatch(
                PostApi.saveContentEmbededVideoLink(
                  response.data.id,
                  values.videoEmbededUrl,
                  values.title
                )
              );
            }
            if (
              values.cover_form !== undefined &&
              values.cover_form.length > 1 &&
              values.cover_form[0].type.split('/')[0] === 'image'
            ) {
              dispatch(
                PostApi.saveContentCarousel(response.data.id, values.cover_form)
              );
            } else if (
              values.cover_form !== undefined &&
              values.cover_form[0].type.split('/')[0] === 'video'
            ) {
              dispatch(
                PostApi.saveContentVideoV2(response.data.id, values.cover_form)
              );
            } else {
              dispatch(
                PostApi.saveContentImage(response.data.id, values.cover_form)
              );
            }
            if (isForm) {
              browserHistory.push('/post/form/create');
              dispatch(PostApi.getTypeForm(false));
            } else {
              dispatch({
                type: 'DRAW_EDIT',
                drawDetails: {
                  rules: '',
                  qty_people: '',
                  winners: '',
                  date_draw: ''
                }
              });

              browserHistory.push('/post/edit');
            }
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static buildOptions(id, values) {}

  static saveContentText(id, values, edit) {
    return (dispatch) => {
      dispatch({
        type: 'SHOW_STATUS',
        enablesubmit: false
      });
      var CONFI = Config.headers();
      CONFI.onUploadProgress = (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        dispatch({
          type: 'SHOW_PROGRESS',
          alert: {
            visible: true,
            percentage: percentCompleted
          }
        });
      };
      var formData = new FormData();
      formData.append('text', values);
      formData.append('kind', 'text');
      API.post(
        'canvas_publications/' + id + '/canvas_contents',
        formData,
        CONFI
      )
        .then(function(response) {
          dispatch({
            type: 'formPost',
            kind: 'text',
            value: response.data
          });
          dispatch({
            type: 'SHOW_PROGRESS',
            alert: {
              visible: false,
              percentage: 0
            }
          });
          dispatch({
            type: 'NO_SUBMITTING'
          });
          try {
            dispatch({
              type: 'formPostEdit',
              kind: 'text',
              value: response.data,
              insertText: true,
              edittext: true
            });
          } catch (e) {}

          dispatch({
            type: 'HIDE_STATUS',
            enablesubmit: true
          });
          PubSub.publish('re-render-canvas', true);
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static isValidImage(values) {
    let image = {
      isInvalid: false
    };
    values.forEach((value) => {
      image.name = value.name;
      image.isInvalid = !imageTypeFilter.includes(value.type);

      if (image.name.length > 30) {
        image.name = `${image.name.substring(0, 15)}(...)${image.name.slice(
          image.name.length - 15
        )}`;
      }
    });
    if (image.isInvalid) {
      return image;
    }
    return values;
  }

  static saveContentImage(id, values) {
    if (values !== undefined) {
      return async (dispatch) => {
        const invalidImage = await this.isValidImage(values);
        if (invalidImage.isInvalid) {
          dispatch({
            type: 'HIDE_STATUS',
            enablesubmit: true
          });
          dispatch({
            type: 'END_LOAD'
          });
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: `Imagem em formato não suportado: ${invalidImage.name}`
            }
          });
          return;
        }

        dispatch({
          type: 'SHOW_STATUS',
          enablesubmit: false
        });
        var CONFI = Config.headers();
        CONFI.onUploadProgress = (progressEvent) => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          dispatch({
            type: 'START_LOAD',
            percent: percentCompleted,
            visible: true
          });
        };
        var formData = new FormData();
        formData.append(
          '[canvas_content_images_attributes][][image]',
          values[0]
        );
        formData.append('kind', 'image');

        API.post(
          'canvas_publications/' + id + '/canvas_contents',
          formData,
          CONFI
        )
          .then(function(response) {
            dispatch({
              type: 'formPost',
              kind: 'image',
              value: response.data
            });
            try {
              dispatch({
                type: 'formPostEdit',
                kind: 'image',
                value: response.data
              });
            } catch (e) {}

            const {
              canvas_content_images,
              canvas_content_videos
            } = response.data;
            if (
              canvas_content_images.length > 0 ||
              canvas_content_videos.length > 0
            ) {
              dispatch({
                type: 'SHOW_PROGRESS',
                alert: {
                  visible: false,
                  percentage: 0
                }
              });
            }
            dispatch({
              type: 'NO_SUBMITTING'
            });
            dispatch({
              type: 'HIDE_STATUS',
              enablesubmit: true
            });
            PubSub.publish('re-render-canvas', true);
            dispatch({
              type: 'END_LOAD'
            });
          })
          .catch(function(err) {
            dispatch({
              type: 'HIDE_STATUS',
              enablesubmit: true
            });
            dispatch({
              type: 'END_LOAD'
            });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          });
      };
    } else {
      return (dispatch) => {
        dispatch({
          type: 'NO_SUBMITTING'
        });
      };
    }
  }

  static saveContentVideo(id, values) {
    return (dispatch) => {
      dispatch({
        type: 'SHOW_STATUS',
        enablesubmit: false
      });
      var CONFI = Config.headers();
      CONFI.onUploadProgress = (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        dispatch({
          type: 'START_LOAD',
          percent: percentCompleted,
          visible: true
        });
      };

      var formData = new FormData();
      formData.append('[canvas_content_videos_attributes][][video]', values[0]);
      formData.append('kind', 'video');
      API.post(
        'canvas_publications/' + id + '/canvas_contents',
        formData,
        CONFI
      )
        .then(function(response) {
          dispatch({
            type: 'formPost',
            kind: 'video',
            value: response.data
          });
          try {
            dispatch({
              type: 'formPostEdit',
              kind: 'image',
              value: response.data
            });
          } catch (e) {}

          dispatch({
            type: 'SHOW_PROGRESS',
            alert: {
              visible: false,
              percentage: 0
            }
          });
          dispatch({
            type: 'HIDE_STATUS',
            enablesubmit: true
          });
          dispatch({
            type: 'NO_SUBMITTING'
          });
          PubSub.publish('re-render-canvas', true);
          dispatch({
            type: 'END_LOAD'
          });
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static saveContentVideoV2(id, values) {
    return async (dispatch) => {
      const f = values[0];
      if (
        (isClientCipa && f.size / (1024 * 1024).toFixed(2) > 500) ||
        (!isClientCipa && f.size / (1024 * 1024).toFixed(2) > 200)
      ) {
        // size validation --> 200mb || 500mb
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: `Não é possível enviar o vídeo, recomendamos o upload de arquivos de vídeo com no máximo ${
              isClientCipa ? '500mb' : '200mb'
            }.`
          }
        });
      } else if (f.type !== 'video/mp4') {
        // type validation --> .mp4
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message:
              'Não é possível enviar o vídeo, recomendamos o upload de arquivos de vídeo no formato .mp4.'
          }
        });
      } else {
        dispatch({
          type: 'SHOW_STATUS',
          enablesubmit: false
        });
        var CONFI = {
          headers: {
            'content-type': `${f.type}`,
            'content-length': `${f.size}`
          }
        };
        CONFI.onUploadProgress = (progressEvent) => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          dispatch({
            type: 'START_LOAD',
            percent: percentCompleted,
            visible: true
          });
        };

        const cover = await getVideoCover(f, 0.1);

        var CONFI_IMAGE = {
          headers: {
            'content-type': `${cover.type}`,
            'content-length': `${cover.size}`
          }
        };

        // const divisor = f.name.split(".");

        const responseLambda = await fetch(
          `https://aheypgto3c.execute-api.us-east-1.amazonaws.com/default/getPresignedURL-1?team=${style.teamName.name}`,
          // `https://pfi6nr3akgr76vbbo2lyu273ui0qnpmf.lambda-url.us-east-1.on.aws?team=${style.teamName.name}&contentType=${f.type}&typeForKey=${divisor[divisor.length - 1]}`,
          {
            method: 'GET'
          }
        ).then((res) => {
          return res.json();
        });

        const video_filename = responseLambda.filename;

        const result = await API_RANDOM_UPLOAD_VIDEO.put(
          responseLambda.url,
          f,
          CONFI
        );
        const result_upload_image = await API_RANDOM_UPLOAD_VIDEO.put(
          responseLambda.url_image,
          cover,
          CONFI_IMAGE
        );

        const formData = new FormData();
        formData.append(
          '[canvas_content_videos_attributes][][video_filename]',
          video_filename
        );
        formData.append('kind', 'video');

        if (result.status === 200 && result_upload_image.status === 200) {
          API_V2.post(
            'canvas_publications/' + id + '/canvas_contents',
            formData,
            Config.headers()
          )
            .then(function(response) {
              dispatch({
                type: 'formPost',
                kind: 'video',
                value: response.data
              });
              try {
                dispatch({
                  type: 'formPostEdit',
                  kind: 'image',
                  value: response.data
                });
              } catch (e) {}

              dispatch({
                type: 'SHOW_PROGRESS',
                alert: {
                  visible: false,
                  percentage: 0
                }
              });
              dispatch({
                type: 'HIDE_STATUS',
                enablesubmit: true
              });
              dispatch({
                type: 'NO_SUBMITTING'
              });
              PubSub.publish('re-render-canvas', true);
              dispatch({
                type: 'END_LOAD'
              });
            })
            .catch(function(err) {
              dispatch({
                type: 'OPEN_ERROR',
                alert: {
                  visible: true,
                  message: errorHandler(err)
                }
              });
            });
        } else {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: 'Houve um erro no upload do vídeo'
            }
          });
        }
      }
    };
  }

  static saveContentCarousel(id, values) {
    return async (dispatch) => {
      let response = false;

      try {
        const { canAddAllFiles } = await canAddFiles(values);
        if (!canAddAllFiles)
          throw new Error('Não foi possível carregar a imagem');

        const invalidImage = await this.isValidImage(values);
        if (invalidImage.isInvalid) {
          dispatch({
            type: 'HIDE_STATUS',
            enablesubmit: true
          });
          dispatch({
            type: 'END_LOAD'
          });
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: `Imagem em formato não suportado: ${invalidImage.name}`
            }
          });
          return;
        }

        dispatch({
          type: 'SHOW_STATUS',
          enablesubmit: false
        });

        var CONFI = Config.headers();
        CONFI.onUploadProgress = (progressEvent) => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          dispatch({
            type: 'START_LOAD',
            percent: percentCompleted,
            visible: true
          });
        };
        var formData = new FormData();
        if (Array.isArray(values)) {
          values.forEach(function(value, i) {
            formData.append(
              '[canvas_content_images_attributes][][image]',
              values[i]
            );
          });
        }
        formData.append('kind', 'carousel');
        API.post(
          'canvas_publications/' + id + '/canvas_contents',
          formData,
          CONFI
        )
          .then(function(response) {
            dispatch({
              type: 'formPost',
              kind: 'carousel',
              value: response.data
            });
            try {
              dispatch({
                type: 'formPostEdit',
                kind: 'image',
                value: response.data
              });
            } catch (e) {}

            dispatch({
              type: 'SHOW_PROGRESS',
              alert: {
                visible: false,
                percentage: 0
              }
            });
            dispatch({
              type: 'HIDE_STATUS',
              enablesubmit: true
            });
            dispatch({
              type: 'NO_SUBMITTING'
            });
            PubSub.publish('re-render-canvas', true);
            dispatch({
              type: 'END_LOAD'
            });
          })
          .catch(function(err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          });
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error),
            submessage:
              error.message === 'Não foi possível carregar a imagem'
                ? 'O tamanho máximo para arquivos de imagem é de até 2000 x 2000 pixels.'
                : ''
          }
        });
      }
      return response;
    };
  }

  static saveContentLink(id, values, title) {
    return (dispatch) => {
      dispatch({
        type: 'SHOW_STATUS',
        enablesubmit: false
      });
      var CONFI = Config.headers();
      CONFI.onUploadProgress = (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        dispatch({
          type: 'START_LOAD',
          percent: percentCompleted,
          visible: true
        });
      };
      var formData = new FormData();

      formData.append('link', values.link_button);
      formData.append('title', values.name_button);
      formData.append('kind', 'link');
      API.post(
        'canvas_publications/' + id + '/canvas_contents',
        formData,
        CONFI
      )
        .then(function(response) {
          dispatch({
            type: 'formPost',
            kind: 'link',
            value: response.data
          });
          try {
            dispatch({
              type: 'formPostEdit',
              kind: 'image',
              value: response.data
            });
          } catch (e) {}

          dispatch({
            type: 'SHOW_PROGRESS',
            alert: {
              visible: false,
              percentage: 0
            }
          });
          dispatch({
            type: 'HIDE_STATUS',
            enablesubmit: true
          });
          dispatch({
            type: 'NO_SUBMITTING'
          });
          PubSub.publish('re-render-canvas', true);
          dispatch({
            type: 'END_LOAD'
          });
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static saveContentEmbededVideoLink(id, link, title) {
    return (dispatch) => {
      dispatch({
        type: 'SHOW_STATUS',
        enablesubmit: false
      });
      var CONFI = Config.headers();
      CONFI.onUploadProgress = (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        dispatch({
          type: 'START_LOAD',
          percent: percentCompleted,
          visible: true
        });
      };
      var formData = new FormData();

      formData.append('link', link);
      formData.append('title', title);
      formData.append('kind', 'embeded_video_link');
      API.post(
        'canvas_publications/' + id + '/canvas_contents',
        formData,
        CONFI
      )
        .then(function(response) {
          dispatch({
            type: 'formPost',
            kind: 'embeded_video_link',
            value: response.data
          });
          try {
            dispatch({
              type: 'formPostEdit',
              kind: 'image',
              value: response.data
            });
          } catch (e) {}

          dispatch({
            type: 'SHOW_PROGRESS',
            alert: {
              visible: false,
              percentage: 0
            }
          });
          dispatch({
            type: 'HIDE_STATUS',
            enablesubmit: true
          });
          dispatch({
            type: 'NO_SUBMITTING'
          });
          PubSub.publish('re-render-canvas', true);
          dispatch({
            type: 'END_LOAD'
          });
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static saveContentQuestion(id, values, edit, type) {
    return (dispatch) => {
      dispatch({
        type: 'SHOW_STATUS',
        enablesubmit: false
      });
      var CONFI = Config.headers();
      CONFI.onUploadProgress = (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        dispatch({
          type: 'SHOW_PROGRESS',
          alert: {
            visible: true,
            percentage: percentCompleted
          }
        });
      };
      var formData = new FormData();
      formData.append('text', '');
      if (type === 'open_question') {
        formData.append('kind', 'open_question');
        formData.append(
          '[open_question_attributes][question]',
          'pergunta qualquer'
        );
      } else {
        formData.append('kind', 'survey');
        formData.append('end_date', values.end_date);
        if (type === 'quiz') {
          formData.append('allows_more_than_one_vote', true);
        }
      }
      API.post(
        'canvas_publications/' + id + '/canvas_contents',
        formData,
        CONFI
      )
        .then(function(response) {
          dispatch({
            type: 'SHOW_PROGRESS',
            alert: {
              visible: false,
              percentage: 0
            }
          });
          dispatch({
            type: 'NO_SUBMITTING'
          });
          dispatch({
            type: 'formPostEdit',
            kind: type,
            value: response.data,
            insertText: true,
            edittext: true
          });
          dispatch({
            type: 'HIDE_STATUS',
            enablesubmit: true
          });
          PubSub.publish('re-render-canvas', true);
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static saveOptionQuestion(idPublish, idContent) {
    return (dispatch) => {
      dispatch({
        type: 'SHOW_STATUS',
        enablesubmit: false
      });
      var CONFI = Config.headers();
      CONFI.onUploadProgress = (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        dispatch({
          type: 'SHOW_PROGRESS',
          alert: {
            visible: true,
            percentage: percentCompleted
          }
        });
      };
      var formData = new FormData();
      formData.append('[surveys_attributes][][title]', '..');
      API.patch(
        `canvas_publications/${idPublish}/canvas_contents/${idContent}`,
        formData,
        CONFI
      )
        .then(function(response) {
          dispatch({
            type: 'SHOW_PROGRESS',
            alert: {
              visible: false,
              percentage: 0
            }
          });
          dispatch({
            type: 'NO_SUBMITTING'
          });
          dispatch({
            type: 'formPostEdit',
            kind: response.data.kind,
            value: response.data,
            createOptionQuestion: true
          });
          dispatch({
            type: 'HIDE_STATUS',
            enablesubmit: true
          });
          PubSub.publish('re-render-canvas', true);
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static editPostId(id) {
    return (dispatch) => {
      dispatch({
        type: 'EDIT_POST_ID',
        id: id
      });
      dispatch({
        type: 'formPostEdit',
        canvas: {}
      });
    };
  }

  static saveContentEdit(id, values, idpatch) {
    return async (dispatch) => {
      try {
        const {
          request: { pendingRequests }
        } = storeCopy.getState();

        dispatch({
          type: 'PATCHING_POST_START'
        });

        const formData = new FormData();
        formData.append('text', values);
        formData.append('kind', 'text');

        const { data } = await API.patch(
          'canvas_publications/' + id + '/canvas_contents/' + idpatch,
          formData,
          Config.headers()
        );

        dispatch({
          type: 'formPostEdit',
          kind: 'text',
          value: data,
          patchTextValue: true
        });

        if (pendingRequests === 0) {
          dispatch({ type: 'PATCHING_POST_FINISH' });
        }
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      } finally {
        dispatch({
          type: 'NO_SUBMITTING'
        });
        dispatch({
          type: 'HIDE_STATUS',
          enablesubmit: true
        });
      }
    };
  }

  static saveContentEditQuestions(id, values, idpatch, type) {
    return (dispatch) => {
      setTimeout(function() {
        if (idpatch) {
          dispatch({
            type: 'SHOW_STATUS',
            enablesubmit: false
          });
          var CONFI = Config.headers();
          CONFI.onUploadProgress = (progressEvent) => {
            dispatch({
              type: 'SHOW_PROGRESS',
              alert: {
                visible: true,
                percentage: progressEvent
              }
            });
            dispatch({
              type: 'HIDE_STATUS',
              enablesubmit: true
            });
            dispatch({
              type: 'NO_SUBMITTING'
            });
            PubSub.publish('re-render-canvas', true);
          };
          var formData = new FormData();
          formData.append('text', values);
          if (type === 'open_question') {
            formData.append('[open_question_attributes][question]', values);
          }
          API.patch(
            `canvas_publications/${id}/canvas_contents/${idpatch}`,
            formData,
            CONFI
          )
            .then(function(response) {
              dispatch({
                type: 'SHOW_PROGRESS',
                alert: {
                  visible: false,
                  percentage: 0
                }
              });
              dispatch({
                type: 'NO_SUBMITTING'
              });
              dispatch({
                type: 'formPostEdit',
                kind: type,
                value: response.data,
                patchTextValue: true
              });
              dispatch({
                type: 'HIDE_STATUS',
                enablesubmit: true
              });
              PubSub.publish('re-render-canvas', true);
            })
            .catch(function(err) {
              dispatch({
                type: 'OPEN_ERROR',
                alert: {
                  visible: true,
                  message: errorHandler(err)
                }
              });
            });
        }
      }, 3000);
    };
  }

  static deleteQuestionContent(idPublish, idContent, type) {
    return (dispatch) => {
      let API_CLIENT = API;

      if (style.teamName.name === 'youseed') {
        API_CLIENT = APIDelete;
      }

      API_CLIENT.delete(
        `canvas_publications/${idPublish}/canvas_contents/${idContent}`,
        Config.headers()
      )
        .then(() => {
          dispatch({
            type: 'formPostEdit',
            kind: type,
            idContent: idContent,
            deleteContent: true
          });
          PubSub.publish('re-render-canvas', true);
          dispatch({
            type: 'NO_SUBMITTING'
          });
        })
        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static deleteOptionQuestion(idContent, idOption, type) {
    return (dispatch) => {
      let API_CLIENT = API;

      if (style.teamName.name === 'youseed') {
        API_CLIENT = APIDelete;
      }

      API_CLIENT.delete(`/surveys/${idOption}`, Config.headers())
        .then(() => {
          dispatch({
            type: 'formPostEdit',
            kind: type,
            deleteOption: true,
            idOption: idOption,
            idContent: idContent
          });
          PubSub.publish('re-render-canvas', true);
          dispatch({
            type: 'NO_SUBMITTING'
          });
        })
        .catch((err) => {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static saveContentEditOptions(id, value, idContent, idOption, type) {
    return (dispatch) => {
      setTimeout(function() {
        if (idContent) {
          dispatch({
            type: 'SHOW_STATUS',
            enablesubmit: false
          });
          var CONFI = Config.headers();
          CONFI.onUploadProgress = (progressEvent) => {
            dispatch({
              type: 'SHOW_PROGRESS',
              alert: {
                visible: true,
                percentage: progressEvent
              }
            });
            dispatch({
              type: 'HIDE_STATUS',
              enablesubmit: true
            });
            dispatch({
              type: 'NO_SUBMITTING'
            });
            PubSub.publish('re-render-canvas', true);
          };
          var formData = new FormData();
          formData.append('[surveys_attributes][][id]', idOption);
          formData.append('[surveys_attributes][][title]', value);
          API.patch(
            `canvas_publications/${id}/canvas_contents/${idContent}`,
            formData,
            CONFI
          )
            .then(function(response) {
              dispatch({
                type: 'SHOW_PROGRESS',
                alert: {
                  visible: false,
                  percentage: 0
                }
              });
              dispatch({
                type: 'NO_SUBMITTING'
              });
              dispatch({
                type: 'formPostEdit',
                kind: type,
                idOption: idOption,
                value: response.data,
                patchOptionValue: true
              });
              dispatch({
                type: 'HIDE_STATUS',
                enablesubmit: true
              });
              PubSub.publish('re-render-canvas', true);
            })
            .catch(function(err) {
              dispatch({
                type: 'OPEN_ERROR',
                alert: {
                  visible: true,
                  message: errorHandler(err)
                }
              });
            });
        }
      }, 3000);
    };
  }

  static EditLink(id, idcontent) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API.get(
        'canvas_publications/' + id + '/canvas_contents/' + idcontent,
        Config.headers()
      )
        .then(function(response) {
          PubSub.publish('re-render-canvas', true);
          var data = response.data;
          dispatch({
            type: 'editlink',
            link: data
          });

          dispatch({
            type: 'SHOW_DIALOG_LINK',
            visible: true
          });

          dispatch({
            type: 'NO_SUBMITTING'
          });
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static patchLink(id, idcontent, values) {
    return (dispatch) => {
      if (!doesContainHttp(values.link)) {
        values.link = `https://${values.link}`;
      }

      var formData = new FormData();
      formData.append('link', values.link);
      formData.append('title', values.title);
      formData.append('kind', 'link');
      API.patch(
        'canvas_publications/' + id + '/canvas_contents/' + idcontent,
        formData,
        Config.headers()
      )
        .then(function(response) {
          PubSub.publish('re-render-canvas', true);
          var data = response.data;
          data.visible = false;
          dispatch({
            type: 'editlink',
            link: data
          });
          dispatch({
            type: 'formPostEdit',
            id: idcontent,
            kind: 'link',
            value: response.data,
            editlink: true
          });
          dispatch({
            type: 'CLOSE_DIALOG_FILE',
            alert: {
              visible: false
            }
          });
          dispatch({
            type: 'NO_SUBMITTING'
          });
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static PatchContentImage(idcanvas, idimage, values, index) {
    return async (dispatch) => {
      try {
        const i = await addImageProcess(URL.createObjectURL(values[0]));
        if (i.height > 2000 || i.width > 2000)
          throw new Error('Não foi possível carregar a imagem');

        dispatch({
          type: 'SHOW_STATUS',
          enablesubmit: false
        });
        var CONFI = Config.headers();
        CONFI.onUploadProgress = (progressEvent) => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          dispatch({
            type: 'SHOW_PROGRESS',
            alert: {
              visible: true,
              percentage: percentCompleted
            }
          });
        };
        var formData = new FormData();
        formData.append(
          '[canvas_content_images_attributes][][image]',
          values[0]
        );
        formData.append('kind', 'image');
        API.patch(
          'canvas_publications/' +
            idcanvas +
            '/canvas_contents/' +
            idimage +
            '?index=' +
            index,
          formData,
          CONFI
        )
          .then(function(response) {
            PubSub.publish('re-render-canvas', true);
            dispatch({
              type: 'formPostEdit',
              kind: 'image',
              value: response.data,
              editimage: true,
              id: response.data.id
            });
            dispatch({
              type: 'SHOW_PROGRESS',
              alert: {
                visible: false,
                percentage: 0
              }
            });
            dispatch({
              type: 'CLOSE_DIALOG_FILE_MIDIA',
              alert: {
                visible: false,
                percentage: 0
              }
            });
            dispatch({
              type: 'HIDE_STATUS',
              enablesubmit: true
            });
            dispatch({
              type: 'NO_SUBMITTING'
            });
          })
          .catch(function(err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          });
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error),
            submessage:
              error.message === 'Não foi possível carregar a imagem'
                ? 'O tamanho máximo para arquivos de imagem é de até 2000 x 2000 pixels.'
                : ''
          }
        });
      }
    };
  }

  static DeleteContentImage(idcanvas, idimage, index, carouselString) {
    return (dispatch) => {
      if (index === undefined) {
        index = 0;
      }
      dispatch({
        type: 'SHOW_STATUS_LOAD',
        state: true
      });

      const formData = new FormData();
      API.patch(
        'canvas_publications/' +
          idcanvas +
          '/canvas_contents/' +
          idimage +
          '?index=' +
          index +
          '&delete' +
          (carouselString === 'carousel' ? '&carousel' : ''),
        formData,
        Config.headers()
      )
        .then(function(response) {
          if (carouselString !== undefined) {
            dispatch({
              type: 'formPostEdit',
              kind: 'carousel',
              value: response.data,
              deletecarousel: true,
              index: index,
              id: response.data.id,
              delete: true
            });
          } else {
            dispatch({
              type: 'formPostEdit',
              kind: 'image',
              value: response.data,
              index: Number(index),
              id: Number(idimage),
              editimage: true,
              delete: true
            });
          }
          dispatch({
            type: 'HIDE_STATUS',
            enablesubmit: true
          });
          dispatch({
            type: 'SHOW_PROGRESS',
            alert: {
              visible: false,
              percentage: 0
            }
          });

          dispatch({
            type: 'HIDE_STATUS_LOAD',
            state: false
          });

          dispatch({
            type: 'NO_SUBMITTING'
          });
          PubSub.publish('re-render-canvas', true);
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static DeleteCotentCarousel(idCanvas, idContent) {
    return (dispatch) => {
      let API_CLIENT = API;

      if (style.teamName.name === 'youseed') {
        API_CLIENT = APIDelete;
      }

      API_CLIENT.delete(
        'canvas_publications/' + idCanvas + '/canvas_contents/' + idContent,
        Config.headers()
      )
        .then(function(response) {
          dispatch({
            type: 'formPostEdit',
            kind: 'carousel',
            deletecarousel: true,
            delete: true,
            idCarousel: Number(idContent)
          });
          dispatch({
            type: 'HIDE_STATUS',
            enablesubmit: true
          });
          dispatch({
            type: 'SHOW_PROGRESS',
            alert: {
              visible: false,
              percentage: 0
            }
          });

          dispatch({
            type: 'HIDE_STATUS_LOAD',
            state: false
          });

          dispatch({
            type: 'NO_SUBMITTING'
          });
          PubSub.publish('re-render-canvas', true);
          dispatch({
            type: 'NO_SUBMITTING'
          });
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static DeleteContentVideo(idcanvas, idimage, index) {
    return (dispatch) => {
      dispatch({
        type: 'SHOW_STATUS',
        enablesubmit: false
      });
      dispatch({
        type: 'SHOW_STATUS_LOAD',
        state: true
      });

      const formData = new FormData();
      API.patch(
        'canvas_publications/' +
          idcanvas +
          '/canvas_contents/' +
          idimage +
          '?index=' +
          0 +
          '&delete&video_edit',
        formData,
        Config.headers()
      )
        .then(function(response) {
          dispatch({
            type: 'formPostEdit',
            kind: 'video',
            value: response.data,
            editvideo: true,
            index: 0,
            id: response.data.id,
            delete: true
          });
          dispatch({
            type: 'HIDE_STATUS',
            enablesubmit: true
          });
          dispatch({
            type: 'SHOW_PROGRESS',
            alert: {
              visible: false,
              percentage: 0
            }
          });

          dispatch({
            type: 'HIDE_STATUS_LOAD',
            state: false
          });
          dispatch({
            type: 'NO_SUBMITTING'
          });

          PubSub.publish('re-render-canvas', true);
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static DeleteContentVideoV2(idcanvas, idimage, index) {
    return (dispatch) => {
      dispatch({
        type: 'SHOW_STATUS',
        enablesubmit: false
      });
      dispatch({
        type: 'SHOW_STATUS_LOAD',
        state: true
      });

      const formData = new FormData();
      API_V2.patch(
        'canvas_publications/' +
          idcanvas +
          '/canvas_contents/' +
          idimage +
          '?index=' +
          0 +
          '&delete&video_edit',
        formData,
        Config.headers()
      )
        .then(function(response) {
          dispatch({
            type: 'formPostEdit',
            kind: 'video',
            value: response.data,
            editvideo: true,
            index: 0,
            id: response.data.id,
            delete: true
          });
          dispatch({
            type: 'HIDE_STATUS',
            enablesubmit: true
          });
          dispatch({
            type: 'SHOW_PROGRESS',
            alert: {
              visible: false,
              percentage: 0
            }
          });

          dispatch({
            type: 'HIDE_STATUS_LOAD',
            state: false
          });
          dispatch({
            type: 'NO_SUBMITTING'
          });

          PubSub.publish('re-render-canvas', true);
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static AddImageToCarousel(idcanvas, idimage, values) {
    return (dispatch) => {
      dispatch({
        type: 'SHOW_STATUS',
        enablesubmit: false
      });
      var CONFI = Config.headers();
      CONFI.onUploadProgress = (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        dispatch({
          type: 'SHOW_PROGRESS',
          alert: {
            visible: true,
            percentage: percentCompleted
          }
        });
      };
      var formData = new FormData();
      if (Array.isArray(values)) {
        values.forEach(function(value, i) {
          formData.append(
            '[canvas_content_images_attributes][][image]',
            values[i]
          );
        });
      }
      API.patch(
        'canvas_publications/' +
          idcanvas +
          '/canvas_contents/' +
          idimage +
          '?carousel=true',
        formData,
        CONFI
      )
        .then(function(response) {
          dispatch({
            type: 'formPostEdit',
            kind: 'carousel',
            value: response.data,
            addcarousel: true,
            addcarouselcount: values.length,
            id: response.data.id
          });
          dispatch({
            type: 'SHOW_PROGRESS',
            alert: {
              visible: false,
              percentage: 0
            }
          });
          dispatch({
            type: 'CLOSE_DIALOG_FILE_MIDIA',
            alert: {
              visible: false,
              percentage: 0
            }
          });
          dispatch({
            type: 'HIDE_STATUS',
            enablesubmit: true
          });
          dispatch({
            type: 'NO_SUBMITTING'
          });
          PubSub.publish('re-render-canvas', true);
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static PatchContentVideo(idcanvas, idimage, values) {
    return (dispatch) => {
      dispatch({
        type: 'SHOW_STATUS',
        enablesubmit: false
      });
      var CONFI = Config.headers();
      CONFI.onUploadProgress = (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        dispatch({
          type: 'SHOW_PROGRESS',
          alert: {
            visible: true,
            percentage: percentCompleted
          }
        });
      };
      var formData = new FormData();
      formData.append('[canvas_content_videos_attributes][][video]', values[0]);
      formData.append('kind', 'video');
      API.patch(
        'canvas_publications/' +
          idcanvas +
          '/canvas_contents/' +
          idimage +
          '?video_edit=true&index=0',
        formData,
        CONFI
      )
        .then(function(response) {
          dispatch({
            type: 'formPostEdit',
            kind: 'video',
            value: response.data,
            editvideo: true,
            id: response.data.id
          });
          dispatch({
            type: 'SHOW_PROGRESS',
            alert: {
              visible: false,
              percentage: 0
            }
          });
          dispatch({
            type: 'CLOSE_DIALOG_FILE_MIDIA',
            alert: {
              visible: false,
              percentage: 0
            }
          });
          dispatch({
            type: 'HIDE_STATUS',
            enablesubmit: true
          });
          dispatch({
            type: 'NO_SUBMITTING'
          });
          PubSub.publish('re-render-canvas', true);
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static PatchContentVideoV2(idcanvas, idimage, values, teamName) {
    return async (dispatch) => {
      const f = values[0];

      if (
        (isClientCipa && f.size / (1024 * 1024).toFixed(2) > 500) ||
        (!isClientCipa && f.size / (1024 * 1024).toFixed(2) > 200)
      ) {
        // size validation --> 200mb || 500mb
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: `Não é possível enviar o vídeo, recomendamos o upload de arquivos de vídeo com no máximo ${
              isClientCipa ? '500mb' : '200mb'
            }.`
          }
        });
      } else if (f.type !== 'video/mp4') {
        // type validation --> .mp4
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message:
              'Não é possível enviar o vídeo, recomendamos o upload de arquivos de vídeo no formato .mp4.'
          }
        });
      } else {
        var CONFI = {
          headers: {
            'content-type': `${f.type}`,
            'content-length': `${f.size}`
          }
        };
        CONFI.onUploadProgress = (progressEvent) => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          dispatch({
            type: 'START_LOAD',
            percent: percentCompleted,
            visible: true
          });
        };

        const cover = await getVideoCover(f, 0.1);

        var CONFI_IMAGE = {
          headers: {
            'content-type': `${cover.type}`,
            'content-length': `${cover.size}`
          }
        };

        // const divisor = f.name.split(".");

        const response = await fetch(
          `https://aheypgto3c.execute-api.us-east-1.amazonaws.com/default/getPresignedURL-1?team=${teamName}`,
          // `https://pfi6nr3akgr76vbbo2lyu273ui0qnpmf.lambda-url.us-east-1.on.aws?team=${teamName}&contentType=${f.type}&typeForKey=${divisor[divisor.length - 1]}`,
          {
            method: 'GET'
          }
        ).then((response) => {
          return response.json();
        });

        const video_filename = response.filename;

        const result = await API_RANDOM_UPLOAD_VIDEO.put(
          response.url,
          f,
          CONFI
        );
        const result_upload_image = await API_RANDOM_UPLOAD_VIDEO.put(
          response.url_image,
          cover,
          CONFI_IMAGE
        );

        var formData = new FormData();
        formData.append(
          '[canvas_content_videos_attributes][][video_filename]',
          video_filename
        );
        formData.append('kind', 'video');

        if (result.status === 200 && result_upload_image.status === 200) {
          API_V2.patch(
            'canvas_publications/' +
              idcanvas +
              '/canvas_contents/' +
              idimage +
              '?video_edit=true&index=0',
            formData,
            Config.headers()
          )
            .then(function(response) {
              dispatch({
                type: 'formPostEdit',
                kind: 'video',
                value: response.data,
                editvideo: true,
                id: response.data.id
              });
              dispatch({
                type: 'SHOW_PROGRESS',
                alert: {
                  visible: false,
                  percentage: 0
                }
              });
              dispatch({
                type: 'CLOSE_DIALOG_FILE_MIDIA',
                alert: {
                  visible: false,
                  percentage: 0
                }
              });
              dispatch({
                type: 'HIDE_STATUS',
                enablesubmit: true
              });
              dispatch({
                type: 'NO_SUBMITTING'
              });
              PubSub.publish('re-render-canvas', true);
              dispatch({
                type: 'END_LOAD'
              });
            })
            .catch(function(err) {
              dispatch({
                type: 'OPEN_ERROR',
                alert: {
                  visible: true,
                  message: errorHandler(err)
                }
              });
            });
        } else {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: 'Houve um erro no upload do vídeo'
            }
          });
        }
      }
    };
  }

  static DeleteCotent(idcanvas, idtext) {
    return (dispatch) => {
      let API_CLIENT = API;

      if (style.teamName.name === 'youseed') {
        API_CLIENT = APIDelete;
      }

      API_CLIENT.delete(
        'canvas_publications/' + idcanvas + '/canvas_contents/' + idtext,
        Config.headers()
      )
        .then(function(response) {
          dispatch({
            type: 'formPostEdit',
            kind: 'text',
            id: Number(idtext),
            delete: true
          });
          PubSub.publish('re-render-canvas', true);
          dispatch({
            type: 'NO_SUBMITTING'
          });
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static deleteComment(post_id, id) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      let API_CLIENT = API;

      if (style.teamName.name === 'youseed') {
        API_CLIENT = APIDelete;
      }

      API_CLIENT.delete(
        'canvas_publications/' + post_id + '/canvas_comments/' + id,
        Config.headers()
      )
        .then(function(response) {
          dispatch({
            type: 'DELETE_COMMENTS',
            id: id,
            post_id: post_id,
            visible: true
          });
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getCommentByNameUser(post_id, name) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API.get(
        'canvas_publications/' + post_id + '/canvas_comments/?name=' + name,
        Config.headers()
      )
        .then(function(response) {
          dispatch({
            type: 'GET_COMMENTS',
            data: response.data,
            post_id: post_id,
            visible: true,
            reset: true
          });
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static getLikesByNameUser(post_id, name) {
    return (dispatch) => {
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API_V2.get('canvas_publications/' + post_id + '/canvas_likes/', {
        ...Config.headers(),
        params: { source: 'admin', user_name: name }
      })
        .then(function(response) {
          dispatch({
            type: 'GET_LIKES',
            data: response.data.users,
            post_id: post_id,
            visible: true,
            reset: true
          });
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        })
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static saveSurvery(values, publish = false, quiz = false) {
    return (dispatch) => {
      var formData = new FormData();
      formData.append('title', values.title);
      if (Array.isArray(values.tags)) {
        values.tags.forEach(function(tag, i) {
          formData.append('tags[]', tag.value);
        });
      }
      formData.append('score', values.score);
      API.post('canvas_publications/', formData, Config.headers())
        .then(
          function(response) {
            var form = this.buildFormEnquete(values, response.data.id);
            form.append('allows_more_than_one_vote', quiz);
            form.append('end_date', values.end_date);
            form.append('not_score_after', values.not_score_after);
            API.post(
              `canvas_publications/${response.data.id}/canvas_contents/`,
              form,
              Config.headers()
            )
              .then(
                function(res) {
                  if (publish) {
                    dispatch(this.canvasPublishSurvery(response.data.id));
                  } else {
                    browserHistory.push('/post/survey');
                  }
                }.bind(this)
              )
              .catch(function(err) {
                if (err.statusCode === 401) {
                  browserHistory.push('./');
                }
                dispatch({
                  type: 'OPEN_ERROR',
                  alert: {
                    visible: true,
                    message: err.data.errors[0]
                  }
                });
              });
          }.bind(this)
        )
        .catch(function(err) {
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }

  static buildFormEnquete(values, id) {
    var form = new FormData();
    if (values.option_0) {
      form.append('[surveys_attributes][][title]', values.option_0);
    }
    if (values.option_1) {
      form.append('[surveys_attributes][][title]', values.option_1);
    }
    if (values.option_2) {
      form.append('[surveys_attributes][][title]', values.option_2);
    }
    if (values.option_3) {
      form.append('[surveys_attributes][][title]', values.option_3);
    }
    if (values.option_4) {
      form.append('[surveys_attributes][][title]', values.option_4);
    }
    form.append('kind', 'survey');
    form.append('text', values.title);
    return form;
  }

  static getSurveyList(page, getMoreSurveys) {
    const randomId = parseInt(Math.random() * 10000000);
    return (dispatch) => {
      dispatch({
        type: 'SET_REQUEST_ACTIVE',
        randomIdRequest: randomId
      });
      dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
      API_V2.get(
        `canvas_contents/survey_content_list?page=${page}&per_page=10`,
        Config.headers()
      )
        .then(function(response) {
          if (getMoreSurveys) {
            dispatch({
              type: 'GET_MORE_SURVEYS',
              payload: response.data
            });
          } else {
            dispatch({
              type: 'GET_SURVERY_LIST',
              payload: response.data
            });
          }
          dispatch({
            type: 'NO_SUBMITTING'
          });
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          dispatch({
            type: 'DELETE_REQUEST_ACTIVE',
            randomIdRequest: randomId
          });
          browserHistory.push('/post/survey');
        })
        .catch(function(err) {
          dispatch({
            type: 'SET_REQUEST_ACTIVE',
            randomIdRequest: randomId
          });
          dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
          dispatch({
            type: 'OPEN_ERROR',
            alert: {
              visible: true,
              message: errorHandler(err)
            }
          });
        });
    };
  }
  static getViewSurvery(contentId, publicationId) {
    const randomId = parseInt(Math.random() * 10000000);
    return async (dispatch) => {
      try {
        dispatch({
          type: 'SET_REQUEST_ACTIVE',
          randomIdRequest: randomId
        });
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });

        const { data: filter_details } = await API.get(
          `canvas_publications/${publicationId}`,
          Config.headers()
        );

        dispatch({
          type: 'GET_FILTER_SURVERY_DETAILS',
          filter_details
        });

        const { data: filter_survey } = await API.get(
          `canvas_publications/*/canvas_contents/${contentId}`,
          Config.headers()
        );

        filter_survey.content_survey.sort(function(init, end) {
          if (init.votes_amount > end.votes_amount) {
            return -1;
          } else if (init.votes_amount < end.votes_amount) {
            return 1;
          } else {
            return 0;
          }
        });
        dispatch({
          type: 'GET_FILTER_SURVERY',
          filter_survey
        });

        dispatch({
          type: 'NO_SUBMITTING'
        });

        dispatch({
          type: 'DELETE_REQUEST_ACTIVE',
          randomIdRequest: randomId
        });

        browserHistory.push('/post/survey/view');
      } catch (error) {
        dispatch({
          type: 'DELETE_REQUEST_ACTIVE',
          randomIdRequest: randomId
        });
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      } finally {
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
      }
    };
  }

  static deleteSurvey(publicationID) {
    return async (dispatch) => {
      const randomId = parseInt(Math.random() * 10000000);
      try {
        dispatch({
          type: 'SET_REQUEST_ACTIVE',
          randomIdRequest: randomId
        });
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
        let API_CLIENT = API;

        if (style.teamName.name === 'youseed') {
          API_CLIENT = APIDelete;
        }

        await API_CLIENT.delete(
          `canvas_publications/${publicationID}`,
          Config.headers()
        );
      } catch (error) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      } finally {
        dispatch({
          type: 'DELETE_REQUEST_ACTIVE',
          randomIdRequest: randomId
        });
        dispatch(this.getSurveyList(1));
        dispatch({
          type: 'NO_SUBMITTING'
        });
        dispatch({
          type: 'CLOSE_ALERT',
          alert: {
            visible: false
          }
        });
      }
    };
  }

  static getViewForm(id, is_publication) {
    return (dispatch) => {
      const randomId = parseInt(Math.random() * 10000000);
      if (!is_publication) {
        dispatch({
          type: 'SET_REQUEST_ACTIVE',
          randomIdRequest: randomId
        });
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
        API.get(`canvas_publications/${id}`, Config.headers())
          .then(
            function(response) {
              var post_parsed = this.parseDetailsForm(
                response.data,
                style.CurrencyName.name
              );
              dispatch({
                type: 'GET_VIEW_FORM',
                formDetails: post_parsed
              });
              dispatch({
                type: 'NO_SUBMITTING'
              });
              dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
              dispatch({
                type: 'DELETE_REQUEST_ACTIVE',
                randomIdRequest: randomId
              });
              browserHistory.push('/post/form/view');
            }.bind(this)
          )
          .catch(function(err) {
            dispatch({
              type: 'DELETE_REQUEST_ACTIVE',
              randomIdRequest: randomId
            });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          });
      } else {
        dispatch({
          type: 'OPEN_ALERT_NO_EDIT',
          alert: true
        });
      }
    };
  }

  static getDetailsDraw(id) {
    const randomId = parseInt(Math.random() * 10000000);
    return async (dispatch) => {
      try {
        dispatch({
          type: 'SET_REQUEST_ACTIVE',
          randomIdRequest: randomId
        });
        dispatch({ type: 'SHOW_PROGRESS_INDETERMINATE' });
        const {
          data: { canvas_contents: contents }
        } = await API.get(`canvas_publications/${id}`, Config.headers());
        const { id: raffleId } = contents.find(
          (content) => content.kind === 'raffle'
        );
        const { data: rawDraw } = await API.get(
          `canvas_publications/*/canvas_contents/${raffleId}`,
          Config.headers()
        );

        dispatch({
          type: 'GET_DETAILS_DRAW',
          viewDrawDetails: this.parseDraw(rawDraw)
        });
        dispatch({ type: 'DRAW_RESULTS_SHOW' });
        dispatch({ type: 'NO_SUBMITTING' });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        dispatch({
          type: 'DELETE_REQUEST_ACTIVE',
          randomIdRequest: randomId
        });
      } catch (error) {
        dispatch({
          type: 'DELETE_REQUEST_ACTIVE',
          randomIdRequest: randomId
        });
        dispatch({ type: 'NO_SUBMITTING' });
        dispatch({ type: 'HIDE_PROGRESS_INDETERMINATE' });
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: errorHandler(error)
          }
        });
      }
    };
  }

  static validateDate(date, kind) {
    const actualDate = new Date();
    if (kind === 'date_time') {
      return date >= actualDate.setMinutes(actualDate.getMinutes() + 15);
    } else if ((kind = 'date')) {
      return !(
        date.getFullYear() === actualDate.getFullYear() &&
        date.getMonth() === actualDate.getMonth() &&
        date.getDate() === actualDate.getDate()
      );
    }
  }

  static saveDraw(idPublish, values, canvasId, raffleId) {
    const { date_draw, qty_people, rulesParsed, selectedTime } = values;
    return (dispatch) => {
      var formData = new FormData();
      formData.append('kind', 'raffle');
      if (raffleId) {
        formData.append('[raffles_attributes][][id]', raffleId);
      }
      date_draw.setHours(selectedTime.selectedHour);
      date_draw.setMinutes(selectedTime.selectedMinutes);
      formData.append('[raffles_attributes][][draw_at]', date_draw);
      formData.append('[raffles_attributes][][draw_date_kind]', 'date_time');
      if (!this.validateDate(date_draw, 'date_time')) {
        dispatch({
          type: 'OPEN_ERROR',
          alert: {
            visible: true,
            message: 'Data ou hora incorretos',
            submessage:
              'Para publicar o sorteio escolha as datas, de hoje, ou posterior. Para o horário, recomendamos uma margem de, no mínimo, 15 minutos a mais do que o atual.'
          }
        });
        return;
      }
      formData.append('[raffles_attributes][][winners_amount]', qty_people);
      switch (rulesParsed) {
        case 'Curtir':
          formData.append('[raffles_attributes][][kind]', 'likes');
          break;
        case 'Comentar':
          formData.append('[raffles_attributes][][kind]', 'comments');
          break;
        case 'Curtir e Comentar':
          formData.append('[raffles_attributes][][kind]', 'likes_and_comments');
          break;
        default:
          throw new Error('Falha em processar regras do sorteio.');
      }
      if (raffleId) {
        API.patch(
          `canvas_publications/${idPublish}/canvas_contents/${canvasId}`,
          formData,
          Config.headers()
        )
          .then((response) => {
            dispatch({
              type: 'DRAW_EDIT',
              drawDetails: this.parseDraw(response.data)
            });
            dispatch({
              type: 'REGISTERED_DRAW',
              registeredDraw: true
            });
            dispatch({ type: 'DRAW_MODAL_CLOSE' });
          })
          .catch((err) => {
            dispatch({ type: 'DRAW_MODAL_CLOSE' });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          });
      } else {
        API.post(
          `canvas_publications/${idPublish}/canvas_contents/`,
          formData,
          Config.headers()
        )
          .then((response) => {
            dispatch({
              type: 'DRAW_EDIT',
              drawDetails: this.parseDraw(response.data)
            });
            dispatch({
              type: 'REGISTERED_DRAW',
              registeredDraw: true
            });
            dispatch({ type: 'DRAW_MODAL_CLOSE' });
          })
          .catch((err) => {
            dispatch({ type: 'DRAW_MODAL_CLOSE' });
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          });
      }
    };
  }

  static parseDraw(valuesDraw) {
    let drawUpdate = {};
    if (valuesDraw.content_raffles[0].draw_date_kind === 'date_time') {
      drawUpdate.date_draw = new Date(valuesDraw.content_raffles[0].draw_at);
    } else {
      drawUpdate.date_draw = new Date(valuesDraw.content_raffles[0].date);
    }
    drawUpdate.canvasId = valuesDraw.id;
    drawUpdate.draw_date_kind = valuesDraw.content_raffles[0].draw_date_kind;
    drawUpdate.raffleId = valuesDraw.content_raffles[0].id;
    drawUpdate.rules = valuesDraw.content_raffles[0].kind;
    drawUpdate.qty_people = valuesDraw.content_raffles[0].winners_amount;
    drawUpdate.winners = valuesDraw.content_raffles[0].winners;
    switch (valuesDraw.content_raffles[0].kind) {
      case 'likes':
        drawUpdate.rulesParsed = 'Curtir';
        break;
      case 'comments':
        drawUpdate.rulesParsed = 'Comentar';
        break;
      case 'likes_and_comments':
        drawUpdate.rulesParsed = 'Curtir e Comentar';
        break;
      default:
        throw new Error('Falha em processar dados do sorteio');
    }
    return drawUpdate;
  }

  static getTypeForm(value) {
    return (dispatch) => {
      dispatch({
        type: 'GET_TYPE_FORM',
        formType: value
      });
    };
  }

  static getHeaderFormDetails(value) {
    return (dispatch) => {
      dispatch({
        type: 'GET_HEADER_FORM_DETAILS',
        nameFormDetails: value
      });
    };
  }

  static checkDrawType(contents) {
    return async (dispatch) => {
      const isDrawType = contents.some((content) => content.kind === 'raffle');

      if (isDrawType) {
        const raffleContent = contents.find(
          (content) => content.kind === 'raffle'
        );
        dispatch({
          type: 'DRAW_EDIT',
          drawDetails: this.parseDraw(raffleContent)
        });
      }

      dispatch({
        type: 'GET_TYPE_DRAW',
        isDrawType
      });
    };
  }

  static sortContent(id_post, contents_position) {
    return (dispatch) => {
      dispatch({
        type: 'PATCHING_POST_START'
      });
      const formData = new FormData();
      formData.append('contents_position', JSON.stringify(contents_position));
      API.patch(
        `canvas_publications/${id_post}/canvas_contents/queue`,
        JSON.stringify(contents_position),
        Config.headersJson()
      )
        .then(
          function(response) {
            dispatch({
              type: 'UPDATE_ELEMENTS_QUEUE',
              elements: response.data
            });
            dispatch({
              type: 'PATCHING_POST_FINISH'
            });
          }.bind(this)
        )
        .catch(
          function(err) {
            dispatch({
              type: 'OPEN_ERROR',
              alert: {
                visible: true,
                message: errorHandler(err)
              }
            });
          }.bind(this)
        );
    };
  }
}
